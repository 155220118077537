import {
    Box,
    Chip,
    Divider,
    IconButton,
    List,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material'
import { getStringForFilter, unpackFilters } from '../../../Insights/Filter'
import React, { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { FilterDialog } from '../../../Insights/FilterDialog'
import { SavedFilterDialog } from '../../../Insights/SavedFilterDialog'
import PlusBlack from '../../../../assets/icons/Plus.svg'
import { FilterItem } from '../../../common/FilterItem/FilterItem'

export const FilterTab = ({
    getWorkspaceMetadata,
    workspaceMetadata,
    initialSelectedColumn,
    initialOpen,
    resetFilterState,
    analyzeChanges,
    chartState,
    setConfig,
    advanced,
    server,
    setTimeRangeDialogOpen,
    config,
}) => {
    const [selectedFilterColumn, setSelectedFilterColumn] = useState(null)
    const [filterDialogOpen, setFilterDialogOpen] = useState(false)
    const [saveFilterDialogOpen, setSaveFilterDialogOpen] = useState(false)
    const [filterToSave, setFilterToSave] = useState(null)
    const [editState, setEditState] = useState(null)
    const [menuOpen, setMenuOpen] = useState(false)
    const summaryFiltersRef = useRef()

    useEffect(() => {
        setSelectedFilterColumn(initialSelectedColumn !== undefined ? initialSelectedColumn : null)
        setFilterDialogOpen(initialOpen !== undefined ? initialOpen : false)
    }, [initialSelectedColumn, initialOpen])

    const getCustomTimeFilterString = () => {
        let result = 'Date: '
        if (config.from) result += 'from ' + dayjs(config.from).format('MM/DD/YYYY')
        if (config.to) {
            if (config.from) result += ' '
            result += 'to ' + dayjs(config.to).format('MM/DD/YYYY')
        }
        return result
    }

    const deleteFilterClick = (event, filter) => {
        if (filter === 'Custom') {
            config.timeRange = 'Last Year'
            config.from = null
            config.to = null
        } else {
            config.filter = chartState.removeFilterItem(filter)
            config.usedDrillAttributes = config.usedDrillAttributes.filter(
                (att) => att !== filter.attributeName
            )
        }
        chartState.getResults(config)
        setConfig({ ...config })
    }

    const getColumnType = (attributeName) => {
        const selectedItem = config.selectedItems.find(
            (item) =>
                (item.kpi && item.kpi === attributeName) ||
                (item.column && item.column === attributeName)
        )
        if (selectedItem) {
            const table = workspaceMetadata?.inboxes?.forEach((inbox) => {
                return inbox.tables.find(
                    (table) => table.reportSeriesTableID === selectedItem.reportSeriesTableID
                )
            })
            return table.columns.find((column) => column.columnName === attributeName).columnType
        }
    }

    const editFilterClick = (f) => {
        setEditState(f)
        setSelectedFilterColumn({
            columnName: f.attributeName,
            columnType: getColumnType(f.attributeName),
            filterValue: f.filterValue.values,
        })
        setFilterDialogOpen(true)
    }

    const isActiveFilter = (f) => {
        if (config.selectedItems.length === 0 || !config.selectedItems.some((i) => i.kpi))
            return false
        const usedKpis = workspaceMetadata.kpis
            .filter((kpi) => config.selectedItems.some((i) => i.kpi === kpi.metricName))
            .map((kpi) => ({ metricName: kpi.metricName, savedFilter: kpi.savedFilterID }))
        if (usedKpis.length === 0) return false
        return usedKpis.find((kpi) => kpi.savedFilter === f.savedFilterKey)
    }

    const unpackedFilters = unpackFilters(chartState.config.filter)

    return (
        <>
            <Box sx={{ height: '50%' }}>
                <Box className={'drawer-section-header'}>
                    <span style={{ fontWeight: 600 }}>Summary filters</span>
                    <IconButton
                        size={'small'}
                        sx={{
                            borderRadius: '5px',
                            marginRight: '5px',
                            border: '1px solid #E6E4E6',
                        }}
                        ref={summaryFiltersRef}
                        onClick={() => {
                            setSelectedFilterColumn(null)
                            setMenuOpen(true)
                        }}
                        disabled={config.selectedItems.length === 0}
                    >
                        <img src={PlusBlack} alt={'menu'} />
                    </IconButton>
                </Box>
                <Box className={'filters-container'}>
                    {config.timeRange === 'Custom' && (config.from || config.to) && (
                        <Chip
                            key={'Time'}
                            label={getCustomTimeFilterString()}
                            onDelete={(event) => deleteFilterClick(event, 'Custom')}
                            onClick={() => setTimeRangeDialogOpen(true)}
                            sx={{ m: 0.5 }}
                        />
                    )}
                    {unpackedFilters?.map((f) => {
                        let s = getStringForFilter(f)
                        return (
                            <Tooltip title={s}>
                                <Chip
                                    key={s}
                                    label={s}
                                    onDelete={(event) => deleteFilterClick(event, f)}
                                    sx={{ m: 0.5 }}
                                    onClick={() => editFilterClick(f)}
                                />
                            </Tooltip>
                        )
                    })}
                </Box>
            </Box>
            <Divider />
            <Box sx={{ height: '50%' }}>
                <Box className={'drawer-section-header'}>
                    <span style={{ fontWeight: 600 }}>Saved filters</span>
                </Box>
                <List
                    sx={{
                        flex: 1,
                        maxWidth: 400,
                        textAlign: 'left',
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100% - 50px)',
                    }}
                >
                    {workspaceMetadata != null &&
                        workspaceMetadata.filters.map((f) => (
                            <FilterItem
                                filter={f}
                                server={server}
                                getWorkspaceMetadata={getWorkspaceMetadata}
                                isActive={isActiveFilter(f)}
                            />
                        ))}
                </List>
            </Box>
            <Menu
                open={menuOpen}
                anchorEl={summaryFiltersRef.current}
                onClose={() => setMenuOpen(false)}
            >
                <MenuItem
                    onClick={() => {
                        setFilterDialogOpen(true)
                        setMenuOpen(false)
                    }}
                >
                    <Typography className={'inter'}>Create new filter</Typography>
                </MenuItem>
                {unpackedFilters.length > 0 && (
                    <MenuItem
                        onClick={() => {
                            setFilterToSave(chartState.config.filter)
                            setMenuOpen(false)
                        }}
                    >
                        <Typography className={'inter'}>Save filter</Typography>
                    </MenuItem>
                )}
            </Menu>
            <FilterDialog
                open={filterDialogOpen}
                setOpen={setFilterDialogOpen}
                workspaceMetadata={workspaceMetadata}
                server={server}
                selectedFilterColumn={selectedFilterColumn}
                setSelectedFilterColumn={setSelectedFilterColumn}
                config={config}
                setConfig={setConfig}
                chartProperties={chartState}
                analyzeChanges={analyzeChanges}
                resetFilterState={resetFilterState}
                editState={editState}
                setEditState={setEditState}
            />
            <SavedFilterDialog
                dialogOpen={saveFilterDialogOpen}
                filterToSave={filterToSave}
                setFilterToSave={setFilterToSave}
                server={server}
                workspaceMetadata={workspaceMetadata}
                getWorkspaceMetadata={getWorkspaceMetadata}
                config={config}
                setConfig={setConfig}
            />
        </>
    )
}
