import Dialog from '../../../common/Dialog/Dialog'
import { Box, Typography } from '@mui/material'
import './NewDatasetModal.css'
import { DatasetOptionBox } from './DatasetOptionBox'
import Mail from '../../../../assets/icons/Mail.svg'
import Upload from '../../../../assets/icons/Upload.svg'
import Blended from '../../../../assets/icons/Blended.svg'
import PlugsConnected from '../../../../assets/icons/PlugsConnected.svg'
import { useState } from 'react'
import Button from '../../../common/Button/Button'

export const SelectSourceStep = ({ onClose, setStep, setOption }) => {
    const [selected, setSelected] = useState('')
    return (
        <Dialog
            open
            onClose={() => {
                onClose()
                setSelected('')
            }}
            actions={
                <Button
                    disabled={selected === ''}
                    className={'button-purple small'}
                    onClick={() => {
                        setStep(1)
                        setOption(selected)
                    }}
                >
                    Continue
                </Button>
            }
            sx={{ textAlign: 'center' }}
        >
            <Typography sx={{ color: '#201024', fontSize: '24px', fontWeight: 600 }}>
                Create New Dataset
            </Typography>
            <Typography>
                Create a new dataset from a one-off or recurring source. Recurring datasets will
                automatically scoop data from the source.
            </Typography>
            <Box className={'dataset-options-container'}>
                <DatasetOptionBox
                    setSelected={setSelected}
                    index={1}
                    selected={selected}
                    icon={PlugsConnected}
                    title={'Application'}
                    description={
                        'Set up an automated connection from any application data source to scoop'
                    }
                    tag={'Recurring'}
                />
                <DatasetOptionBox
                    setSelected={setSelected}
                    index={2}
                    selected={selected}
                    icon={Blended}
                    title={'Existing Scoop Data'}
                    description={
                        'Extract data from one or more existing datasets with the option of blending'
                    }
                    tag={'Recurring'}
                />
                <DatasetOptionBox
                    setSelected={setSelected}
                    index={3}
                    selected={selected}
                    icon={Upload}
                    title={'Data File'}
                    description={
                        'Upload your file directly into Scoop and visualise the data immediately'
                    }
                    tag={'One-time'}
                />
                <DatasetOptionBox
                    setSelected={setSelected}
                    index={4}
                    selected={selected}
                    icon={Mail}
                    title={'Data Email'}
                    description={
                        'Forward any email containing data to Scoop and visualise the data immediately'
                    }
                    tag={'One-time/Recurring'}
                />
            </Box>
        </Dialog>
    )
}
