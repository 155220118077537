import React, { useEffect, useState } from 'react'
import './CreateThemeDialog.css'
import Dialog from '../../../../../common/Dialog/Dialog'
import { Box, FormControl, IconButton, MenuItem, TextField } from '@mui/material'
import Button from '../../../../../common/Button/Button'
import Typography from '@mui/material/Typography'
import { ScoopColorPicker } from '../../../../../common/ScoopColorPicker/ScoopColorPicker'
import Trash from '../../../../../../assets/icons/Trash.svg'
import Plus from '../../../../../../assets/icons/Plus.svg'
import Selector from '../../../../../common/Selector/Selector'
import chroma from 'chroma-js'
import { ScoopLoader } from '../../../../../common/Spinner/ScoopLoader'
import { Switch } from '../../../../../common/Switch/Switch'
import { getDefaultChartPreferences } from '../utils'
import { useApi } from '../../../../../../api/api'
import { useSelector } from 'react-redux'

export const CreateThemeDialog = ({
    createThemeOpen,
    setCreateThemeOpen,
    workspaceMetadata,
    server,
    getWorkspaceMetadata,
}) => {
    const { postData: postCanvasData } = useApi(
        'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/canvasV2'
    )
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const userID = useSelector((state) => state.auth.userID)

    const [themeName, setThemeName] = useState('')
    const [newPalette, setNewPalette] = useState(false)
    const [backgroundColor, setBackgroundColor] = useState('#FFF')
    const [baseColors, setBaseColors] = useState(['#F5568A', '#7E84FA'])
    const [colorScheme, setColorScheme] = useState(
        workspaceMetadata?.themes[0]?.colorScheme || undefined
    )
    const [colorsNumber, setColorsNumber] = useState(5)
    const [colorSpace, setColorSpace] = useState('HSL')
    const [palette, setPalette] = useState([])
    const [darkTheme, setDarkTheme] = useState(false)
    const [loading, setLoading] = useState(false)
    const [canvases, setCanvases] = useState([])

    useEffect(() => {
        generatePalette()
    }, [baseColors, colorSpace, colorsNumber])

    useEffect(() => {
        if (createThemeOpen && workspaceMetadata.themes.length < 1) {
            const action = {
                action: 'listCanvases',
                workspaceID: workspaceID,
                userID: userID,
                isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
            }
            postCanvasData(action).then((result) => setCanvases(result))
        }
    }, [createThemeOpen])

    const generatePalette = () => {
        setPalette(
            chroma.scale(baseColors).mode(colorSpace.toLocaleLowerCase()).colors(colorsNumber)
        )
    }

    const handleSave = () => {
        setLoading(true)
        const colors = newPalette ? palette : colorScheme.colors.map((c) => c.val)
        server.postData(
            {
                action: 'getNewID',
                class: 'scoop.presentation.Theme',
            },
            (r) => {
                const canvasID =
                    workspaceMetadata.themes.length > 0
                        ? workspaceMetadata.themes[0].canvasID
                        : canvases[0]?.canvasID
                server.postData(
                    {
                        action: 'putObject',
                        class: 'scoop.presentation.Theme',
                        value: {
                            themeID: 'T' + r.newID,
                            canvasID: canvasID,
                            themeName: themeName,
                            colorScheme: {
                                name: themeName,
                                darkTheme: darkTheme,
                                backgroundColor:
                                    backgroundColor.length > 7
                                        ? backgroundColor.slice(0, -2)
                                        : backgroundColor,
                                colors: colors.map((c, i) => ({
                                    name: 'Color ' + (i + 1),
                                    val: c,
                                })),
                            },
                            fonts: [{ family: 'Source Sans Pro', variant: '400' }],
                            chartPreferences: getDefaultChartPreferences(darkTheme),
                        },
                    },
                    (r) => {
                        getWorkspaceMetadata()
                        setLoading(false)
                        onClose()
                    }
                )
            }
        )
    }

    const onClose = () => {
        setThemeName('')
        setNewPalette(false)
        setBackgroundColor('#FFF')
        setBaseColors(['#F5568A', '#7E84FA'])
        setColorScheme(workspaceMetadata?.themes[0]?.colorScheme || undefined)
        setColorsNumber(5)
        setColorSpace('HSL')
        setPalette([])
        setDarkTheme(false)
        setCreateThemeOpen(false)
    }

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={onClose} className={'primary-button'} text={'Cancel'} />
            <Button
                onClick={handleSave}
                className={'primary-button button-purple'}
                disabled={loading}
            >
                {loading ? <ScoopLoader /> : <Typography className={'inter'}>Confirm</Typography>}
            </Button>
        </Box>
    )

    return (
        <Dialog
            onClose={onClose}
            open={createThemeOpen}
            actions={actions}
            title={'Create theme'}
            maxWidth={800}
        >
            <FormControl
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                }}
            >
                <label className={'prompt-label-label'} style={{ marginBottom: 0 }}>
                    Theme name
                </label>
                <TextField
                    className={'prompt-label-input'}
                    value={themeName}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setThemeName(e.target.value)}
                />
            </FormControl>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography className={'inter'} sx={{ fontSize: '12px', fontWeight: 600 }}>
                        Background color
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: 40,
                        }}
                    >
                        <ScoopColorPicker
                            height={20}
                            width={20}
                            value={backgroundColor}
                            origin={'right'}
                            onChange={(color) => setBackgroundColor(color)}
                        />
                        <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                            {backgroundColor}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        ml: '10px',
                    }}
                >
                    <Typography
                        className={'inter'}
                        sx={{ fontSize: '12px', fontWeight: 600, ml: '5px' }}
                    >
                        Dark theme
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: 40,
                        }}
                    >
                        <Switch checked={darkTheme} onClick={() => setDarkTheme(!darkTheme)} />
                    </Box>
                </Box>
            </Box>
            {newPalette && (
                <>
                    <Typography className={'inter'} sx={{ fontSize: '12px', fontWeight: 600 }}>
                        Palette base colors
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {baseColors.map((color, i) => {
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        height: 40,
                                    }}
                                >
                                    <ScoopColorPicker
                                        height={20}
                                        width={20}
                                        value={color}
                                        origin={'right'}
                                        onChange={(color) => {
                                            const temp = [...baseColors]
                                            temp[i] = color
                                            setBaseColors(temp)
                                        }}
                                    />
                                    <Typography className={'inter'} sx={{ fontSize: '14px' }}>
                                        {color}
                                    </Typography>
                                    {i > 1 && (
                                        <IconButton
                                            onClick={() => {
                                                const temp = [...baseColors]
                                                temp.splice(i, 1)
                                                setBaseColors(temp)
                                            }}
                                        >
                                            <img src={Trash} alt={'trash'} style={{ width: 15 }} />
                                        </IconButton>
                                    )}
                                </Box>
                            )
                        })}
                        {baseColors.length < 5 && (
                            <IconButton onClick={() => setBaseColors([...baseColors, '#FFF'])}>
                                <img src={Plus} alt={'plus'} style={{ width: 15 }} />
                            </IconButton>
                        )}
                    </Box>
                </>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                }}
            >
                {newPalette && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Box sx={{ width: 150 }}>
                            <Selector
                                value={colorsNumber}
                                label={'Number of colors'}
                                labelClassName={'selector-label-bold'}
                                sx={{ height: 40 }}
                            >
                                <MenuItem value={5} onClick={() => setColorsNumber(5)}>
                                    <Typography className={'inter'}>5</Typography>
                                </MenuItem>
                                <MenuItem value={10} onClick={() => setColorsNumber(10)}>
                                    <Typography className={'inter'}>10</Typography>
                                </MenuItem>
                                <MenuItem value={20} onClick={() => setColorsNumber(20)}>
                                    <Typography className={'inter'}>20</Typography>
                                </MenuItem>
                                <MenuItem value={30} onClick={() => setColorsNumber(30)}>
                                    <Typography className={'inter'}>30</Typography>
                                </MenuItem>
                            </Selector>
                        </Box>
                        <Box sx={{ width: 100, ml: '10px' }}>
                            <Selector
                                value={colorSpace}
                                label={'Color space'}
                                labelClassName={'selector-label-bold'}
                                sx={{ height: 40 }}
                            >
                                <MenuItem value={'HSL'} onClick={() => setColorSpace('HSL')}>
                                    <Typography className={'inter'}>HSL</Typography>
                                </MenuItem>
                                <MenuItem value={'LAB'} onClick={() => setColorSpace('LAB')}>
                                    <Typography className={'inter'}>LAB</Typography>
                                </MenuItem>
                                <MenuItem value={'LCH'} onClick={() => setColorSpace('LCH')}>
                                    <Typography className={'inter'}>LCH</Typography>
                                </MenuItem>
                            </Selector>
                        </Box>
                    </Box>
                )}
                {!newPalette && (
                    <Box sx={{ width: 300 }}>
                        <Selector
                            value={colorScheme || ''}
                            label={'Color palette'}
                            labelClassName={'selector-label-bold'}
                            sx={{ height: 40 }}
                            renderValue={(s) => s.name}
                        >
                            {workspaceMetadata?.themes?.map((theme) => {
                                return theme.colorScheme ? (
                                    <MenuItem
                                        value={theme.colorScheme}
                                        key={theme.themeID}
                                        onClick={() => setColorScheme(theme.colorScheme)}
                                    >
                                        <Typography className={'inter'}>
                                            {theme.colorScheme.name}
                                        </Typography>
                                    </MenuItem>
                                ) : null
                            })}
                        </Selector>
                    </Box>
                )}
                <Button
                    className={'button-grey new-palette-button'}
                    onClick={() => setNewPalette(!newPalette)}
                >
                    {newPalette ? 'Choose palette' : 'New palette'}
                </Button>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mt: '10px',
                    flexWrap: 'wrap',
                }}
            >
                {!newPalette &&
                    colorScheme?.colors &&
                    colorScheme.colors.map((color, i) => (
                        <Box
                            key={'color-' + i}
                            sx={{
                                backgroundColor: color.val,
                                height: 20,
                                width: 20,
                                borderRadius: '5px',
                                mr: '5px',
                                mt: '5px',
                                border: '1px solid #979099',
                            }}
                        />
                    ))}
                {newPalette &&
                    palette.map((color, i) => (
                        <Box
                            key={'color-' + i}
                            sx={{
                                backgroundColor: color,
                                height: 20,
                                width: 20,
                                borderRadius: '5px',
                                mr: '5px',
                                mt: '5px',
                                border: '1px solid #979099',
                            }}
                        />
                    ))}
            </Box>
        </Dialog>
    )
}
