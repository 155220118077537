import { Box } from '@mui/material'
import Button from '../common/Button/Button'
import * as React from 'react'
import Dialog from '../common/Dialog/Dialog'
import { renderSelector } from '../screens/Explorer/DrawerTabs/Style/utils'
import { useEffect, useState } from 'react'
import Input from '../common/Input/Input'
import { ScoopLoader } from '../common/Spinner/ScoopLoader'
import { Server } from '../../api/Server'
import { useSelector } from 'react-redux'

const typeOptions = [
    { label: 'Column', value: 'column' },
    { label: 'Line', value: 'line' },
    { label: 'Bar', value: 'bar' },
    { label: 'Pie', value: 'pie' },
    { label: 'Table', value: 'table' },
    { label: 'Kpi', value: 'kpi' },
]

export const GenerateInsightDialog = ({
    open,
    onClose,
    inboxes,
    onGenerateSuccess,
    onGenerateError,
    generateInsight,
    workspaceMetadata,
}) => {
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const userID = useSelector((state) => state.auth.userID)
    const token = useSelector((state) => state.auth.token)
    const [selectedInbox, setSelectedInbox] = useState(inboxes?.[0]?.inboxID)
    const [selectedType, setSelectedType] = useState('column')
    const [numSlides, setNumSlides] = useState(5)
    const [prompt, setPrompt] = useState('')
    const [isGenerateInsightLoading, setIsGenerateInsightLoading] = useState(false)
    const [maxSlidesExceeded, setMaxSlidesExceeded] = useState(false)
    const [server, setServer] = useState(new Server(workspaceID, userID, token))
    const [title, setTitle] = useState('Presentation')

    const maxSlides = workspaceMetadata && workspaceMetadata.workspaceName.includes('Tour') ? 5 : 10

    useEffect(() => {
        setServer(new Server(workspaceID, userID, token))
    }, [workspaceID, userID, token])

    useEffect(() => {
        setSelectedInbox(inboxes?.[0]?.inboxID)
    }, [inboxes])

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={onClose} text={'Cancel'} />
            <Button
                disabled={
                    isGenerateInsightLoading ||
                    !selectedInbox ||
                    (generateInsight && !selectedType) ||
                    (!generateInsight && numSlides > maxSlides) ||
                    !prompt
                }
                onClick={() =>
                    handleGenerateInsight(
                        selectedInbox,
                        selectedType,
                        prompt,
                        title,
                        Number(numSlides)
                    )
                }
                text={isGenerateInsightLoading ? <ScoopLoader /> : 'Generate'}
                className={'button-purple'}
            />
        </Box>
    )

    const handleSlidesNumberChange = (e) => {
        const value = e.target.value
        if (value <= maxSlides && value > 0) {
            setNumSlides(value)
        }
    }

    const handleGenerateInsight = (inboxID, type, prompt, title, numSlides) => {
        setIsGenerateInsightLoading(true)
        const action = {
            action: generateInsight ? 'generateAIInsight' : 'generateAIPresentation',
            inboxID,
            prompt,
            title,
            ...(generateInsight ? { type } : { numSlides }),
        }
        server.sheetPostData(action, (response) => {
            if (response.status === 'error') {
                onGenerateError(response?.error)
            } else if (response.error) {
                onGenerateError(response.error)
            } else if (response.message) {
                onGenerateError(response.message)
            } else {
                onGenerateSuccess(response)
            }
            setIsGenerateInsightLoading(false)
            onClose()
        })
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title={`Generate a New ${generateInsight ? 'Summary' : 'Presentation'} With AI`}
            actions={actions}
            style={{ width: 600 }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Box sx={{ height: '72px', display: 'flex', gap: '16px' }}>
                    {renderSelector(
                        'Select Dataset',
                        selectedInbox,
                        (inbox) => setSelectedInbox(inbox),
                        inboxes
                            .filter(
                                (inbox) =>
                                    inbox.label !== 'Pipeline Analysis' ||
                                    !workspaceMetadata.workspaceName.includes('Tour')
                            )
                            .map((inbox) => ({
                                label: inbox.label,
                                value: inbox.inboxID,
                            }))
                    )}
                    <Box sx={{ width: generateInsight ? '70%' : '50%' }}>
                        {generateInsight ? (
                            renderSelector(
                                'Select Chart Type',
                                selectedType,
                                (type) => setSelectedType(type),
                                typeOptions
                            )
                        ) : (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                <Input
                                    value={numSlides}
                                    onChange={(e) => handleSlidesNumberChange(e)}
                                    type={'number'}
                                    label={'Number of Slides'}
                                    fullWidth
                                />
                                {maxSlidesExceeded && (
                                    <p style={{ color: 'red', fontSize: '10px', margin: '0' }}>
                                        {maxSlides} is the maximum number allowed
                                    </p>
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
                {!generateInsight && (
                    <Box>
                        <p
                            style={{ fontSize: '12px', fontWeight: '600', margin: '0 0 4px 0' }}
                            className={'inter'}
                        >
                            Presentation Title
                        </p>
                        <Input
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            fullWidth
                            padding={0.3}
                        />
                    </Box>
                )}
                <Box>
                    <p
                        style={{ fontSize: '12px', fontWeight: '600', margin: '0 0 4px 0' }}
                        className={'inter'}
                    >
                        Type your prompt here
                    </p>
                    <Input
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        multiline
                        rows={4}
                        placeholder={'Start typing your prompt here...'}
                        fullWidth
                        padding={'0'}
                    />
                </Box>
            </Box>
        </Dialog>
    )
}
