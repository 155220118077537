import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { Server } from '../../../../api/Server'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../router/routes'
import { useApi } from '../../../../api/api'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'

export const APIConnectorAuth = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { postData: postDataDefault } = useApi()
    const userID = useSelector((state) => state.auth.userID)
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    const token = useSelector((state) => state.auth.token)
    const [connector, setConnector] = React.useState(null)
    const [loadingAuth, setLoadingAuth] = useState(false)
    // this flow means the user came in via the setup page and now has to authorize gmail
    const [isFinishSetupFlow, setIsFinishSetupFlow] = useState(false)

    useEffect(() => {
        if (!isAuthenticated) {
            localStorage.setItem('redirectUrl', `${location.pathname}${location.search}`)
            navigate(ROUTES.LOGIN)
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (!token || !userID) return
        setLoadingAuth(true)
        let queryString = window.location.search
        let code = null
        let code_challenge = null
        let apiWorkspaceID = null
        if (queryString.indexOf('?', 1) >= 0) {
            queryString =
                queryString.substring(0, queryString.indexOf('?', 1)) +
                '&' +
                queryString.substring(queryString.indexOf('?', 1) + 1)
        }
        let queryParams = new URLSearchParams(queryString)
        let connector = queryParams.get('connector')
        setConnector(connector)
        let additionalParameters = []
        queryParams.forEach((value, key) => {
            if (key === 'code') {
                code = value
            } else if (key === 'state') {
                apiWorkspaceID = value
            } else if (key === 'code_challenge') {
                code_challenge = value
            } else if (key !== 'connector') {
                additionalParameters.push({ key: key, value: value })
            }
        })
        if (code && token && userID) {
            const decodedCode = decodeURIComponent(code)
            const decodedCodeChallenge = code_challenge ? decodeURIComponent(code_challenge) : null
            const decodeConnector = decodeURIComponent(connector)
            const authWorkspace = decodeURIComponent(apiWorkspaceID)
            if (!authWorkspace || authWorkspace === 'null') {
                const getUserDataAction = {
                    action: 'getObjects',
                    attributeName: 'userID',
                    attributeValue: userID,
                    class: 'scoop.user.User',
                }
                postDataDefault(getUserDataAction).then((r) => {
                    const userData = r.objects[0]
                    if (userData) {
                        const personalWorkspace = userData.personalWorkspace
                        if (!personalWorkspace) {
                            const getUserWorkspaces = {
                                action: 'getObjects',
                                attributeName: 'userID',
                                class: 'scoop.workspace.Workspace',
                                attributeValue: userID,
                                relatedClass: 'scoop.workspace.WorkspaceUser',
                            }
                            postDataDefault(getUserWorkspaces).then((r) => {
                                const workspaces = r.objects
                                if (workspaces.length > 0) {
                                    const personal = workspaces[0].workspaceID
                                    setLoadingAuth(false)
                                    const newServer = new Server(personal, userID, token)
                                    saveAuth(
                                        decodedCode,
                                        connector,
                                        decodedCodeChallenge,
                                        newServer,
                                        additionalParameters
                                    )
                                }
                            })
                        } else {
                            setLoadingAuth(false)
                            const newServer = new Server(personalWorkspace, userID, token)
                            saveAuth(
                                decodedCode,
                                connector,
                                decodedCodeChallenge,
                                newServer,
                                additionalParameters
                            )
                        }
                    }
                })
            } else {
                const newServer = new Server(authWorkspace, userID, token)
                saveAuth(
                    decodedCode,
                    connector,
                    decodedCodeChallenge,
                    newServer,
                    additionalParameters
                )
            }
        } else if (queryParams.toString() !== '') {
            setIsFinishSetupFlow(false)
        }
    }, [userID, token])

    const saveAuth = (code, connector, decodedCodeChallenge, server, additionalParameters) => {
        let action = {
            action: 'handleOAuthRedirect',
            code: code,
            connectionType: connector,
            queryParams: additionalParameters,
        }
        if (decodedCodeChallenge) action.codeChallenge = decodedCodeChallenge
        server.postData(action, (results) => {
            if (results && results.oauth && results.oauth === 'success') {
                setIsFinishSetupFlow(true)
                if (connector.toLowerCase() === 'pipedrive') navigate(ROUTES.CANVAS)
            } else {
                setIsFinishSetupFlow(false)
            }
            setLoadingAuth(false)
        })
    }

    if (!isAuthenticated) return null

    return (
        <Box height={'100vh'} width={'100vw'} sx={{ display: 'grid', placeContent: 'center' }}>
            {loadingAuth ? (
                <ScoopLoader size={40} />
            ) : (
                <Box>
                    <Typography className={'inter'} fontSize={40}>
                        Connection to {connector}{' '}
                        {isFinishSetupFlow ? 'successful' : 'unsuccessful'}
                    </Typography>
                    <Typography className={'inter'} sx={{ mt: '20px' }}>
                        {isFinishSetupFlow
                            ? 'You can now close this page and return to Scoop.'
                            : 'Please verify that you have the appropriate permissions to connect to the application from Scoop.'}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}
