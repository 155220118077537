import {
    Box,
    Paper,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from '@mui/material'
import './DatasetInsight.css'
import React, { useEffect, useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { StatusBubble } from '../../../common/StatusBubble/StatusBubble'
import IconButton from '@mui/material/IconButton'
import DatabaseBlack from '../../../../assets/icons/DatabaseBlack.svg'
import Trash from '../../../../assets/icons/Trash.svg'
import XCircle from '../../../../assets/icons/XCircle.svg'
import DeleteDialog from '../../../common/Dialog/DeleteDialog'
import { Server } from '../../../../api/Server'
import { useSelector } from 'react-redux'
import ScoopDrawer from './ScoopDrawer'
import { Toast } from '../../../common/Toast/Toast'
import { tab } from '@testing-library/user-event/dist/tab'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

export const CustomTableCell = ({
    children,
    active,
    direction,
    setActive,
    setDirection,
    field,
    ...props
}) => {
    return (
        <TableCell
            sx={{
                fontWeight: 'normal', // adjust the weight as needed
                color: '#000', // text color
                pb: 0.75, // padding bottom
                pt: 0.75, // padding top
                backgroundColor: '#F2F2F2',
                border: '1px solid #F2F2F2',
            }}
            {...props}
        >
            <TableSortLabel
                active={active === field}
                direction={direction}
                sx={{
                    '&.Mui-active': {
                        color: '#000', // active label color
                    },
                    '&:hover': {
                        color: '#000', // hover label color
                    },
                    '& .MuiTableSortLabel-icon': {
                        color: '#000 !important', // arrow color
                    },
                }}
                IconComponent={ArrowDropDownIcon}
                onClick={() => {
                    if (active === field) {
                        setDirection(direction === 'desc' ? 'asc' : 'desc')
                    } else {
                        setActive(field)
                        setDirection('desc')
                    }
                }}
            >
                {children}
            </TableSortLabel>
        </TableCell>
    )
}
const matchS3key = (str1, str2) => {
    if (str1 && str2) {
        // Normalize the strings by replacing spaces with '+' and vice versa
        let normalizedStr1 = str1.replace(/ /g, '+')
        let normalizedStr2 = str2.replace(/ /g, '+')

        return normalizedStr1 === normalizedStr2
    }
    return false
}

export const ScoopHistory = ({
    logs,
    inboxID,
    refreshLogs,
    tables,
    setOpenReprocess,
    processStatus,
    dataset,
}) => {
    const userID = useSelector((state) => state.auth.userID)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const token = useSelector((state) => state.auth.token)

    const [active, setActive] = useState('date')
    const [direction, setDirection] = useState('desc')
    const [pending, setPending] = useState(-1)
    const [sortedLogs, setSortedLogs] = useState(logs)
    const [deleteLog, setDeleteLog] = useState(null)
    const [openDrawer, setOpenDrawer] = useState(null)
    const [logsDetails, setLogsDetails] = useState([])
    const [alert, setAlert] = useState(null)
    const [isReprocess, setIsReprocess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [curLog, setCurLog] = React.useState(null)

    const getLogs = async () => {
        setIsLoading(true)
        const server = new Server(workspaceID, userID, token)
        try {
            await server.postData(
                {
                    action: 'getEventLogItems',
                    inboxID: inboxID,
                    userID: userID,
                },
                processEventLog
            )
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }
    const processEventLog = (results) => {
        const auxLogs = []
        results?.eventLogItems?.map((log) => {
            if (log.inboxID === inboxID) auxLogs.push(log)
        })
        setLogsDetails(auxLogs)
    }

    useEffect(() => {
        getLogs()
    }, [])

    const handleDeleteLog = (response) => {
        setAlert({
            message: response.message ?? 'Log deleted successfully',
            severity: 'success',
        })
        refreshLogs()
    }
    const handleError = (response) => {
        setAlert({
            message: response.message ?? 'There was an error deleting the Log',
            severity: 'error',
        })
    }

    const sortData = (field) => {
        const sortedData = [...logs]

        sortedData.sort((a, b) => {
            if (field === 'inferredDate') {
                if (Date(a[field]) < Date(b[field]) || !a[field]) {
                    return direction === 'desc' ? 1 : -1
                }
                if (Date(a[field]) > Date(b[field]) || !b[field]) {
                    return direction === 'desc' ? -1 : 1
                }
            }
            if (a[field] < b[field] || !a[field]) {
                return direction === 'desc' ? 1 : -1
            }
            if (a[field] > b[field] || !b[field]) {
                return direction === 'desc' ? -1 : 1
            }
            return 0
        })
        for (let i = 0; i < sortedData.length; i++) {
            let log = sortedData[i]
            let logDetail = logsDetails?.find((logDetail) => matchS3key(logDetail.s3Key, log.s3key))
            if (logDetail) {
                log.detail = logDetail
            }
        }

        return setSortedLogs(sortedData)
    }
    useEffect(() => {
        sortData(active)
    }, [active, direction, logs, logsDetails])

    const handleDelete = () => {
        const server = new Server(workspaceID, userID, token)
        if (deleteLog) {
            if (deleteLog.rstiKey) {
                server.postData(
                    {
                        action: 'deleteIngestion',
                        inboxID: inboxID,
                        rstiKey: deleteLog.rstiKey,
                    },
                    (response) => {
                        handleDeleteLog(response)
                    },
                    {},
                    (response) => handleError(response)
                )
            } else {
                server.postData(
                    {
                        action: 'deleteIngestion',
                        inboxID: inboxID,
                        s3Key: deleteLog.s3key,
                    },
                    (response) => {
                        handleDeleteLog(response)
                    },
                    {},
                    (response) => handleError(response)
                )
            }
            setDeleteLog(null)
        }
    }

    const handleLogDetailPopup = (event, log) => {
        setAnchorEl(event.currentTarget)
        setCurLog(log)
    }

    const handleCloseLogDetailPopup = () => {
        setAnchorEl(null)
    }

    const popoverOpen = Boolean(anchorEl)

    return (
        <>
            <Paper sx={{ maxHeight: '100%', overflow: 'auto' }}>
                {logs?.length > 0 ? (
                    <Table sx={{ borderCollapse: 'separate', height: '100%' }}>
                        <TableHead sx={{ position: 'sticky', top: '0', zIndex: 999 }}>
                            <TableRow>
                                <CustomTableCell
                                    sx={{
                                        '&:first-of-type': {
                                            borderTopLeftRadius: '5px',
                                            fontWeight: 'normal',
                                            color: '#000',
                                            border: '1px solid #F2F2F2',
                                            pb: 0.75,
                                            pt: 0.75,
                                            backgroundColor: '#F2F2F2',
                                        },
                                    }}
                                    active={active}
                                    setActive={setActive}
                                    direction={direction}
                                    setDirection={setDirection}
                                    field={'inferredDate'}
                                >
                                    Date
                                </CustomTableCell>
                                <CustomTableCell
                                    active={active}
                                    setActive={setActive}
                                    direction={direction}
                                    setDirection={setDirection}
                                    field={'rstiKey'}
                                >
                                    Status
                                </CustomTableCell>
                                <CustomTableCell
                                    active={active}
                                    setActive={setActive}
                                    direction={direction}
                                    field={'inferredDate'}
                                    setDirection={setDirection}
                                >
                                    Table
                                </CustomTableCell>
                                <CustomTableCell
                                    sx={{
                                        '&:last-of-type': {
                                            borderTopRightRadius: '5px',
                                            fontWeight: 'normal',
                                            color: '#000',
                                            border: '1px solid #F2F2F2',
                                            pb: 0.75,
                                            pt: 0.75,
                                            backgroundColor: '#F2F2F2',
                                        },
                                    }}
                                    field={'inferredDate'}
                                    active={active}
                                    setActive={setActive}
                                    direction={direction}
                                    setDirection={setDirection}
                                    fi
                                >
                                    Details
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedLogs?.map((log, index) => (
                                <TableRow key={log.id}>
                                    <TableCell sx={{ borderLeft: '1px solid #E6E4E6' }}>
                                        {log.inferredDate}
                                    </TableCell>
                                    <TableCell>
                                        <StatusBubble
                                            status={log.rstiKey ? 'Success' : 'Failed'}
                                            withLabel
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {
                                            tables?.find(
                                                (table) =>
                                                    table.reportSeriesTableID ===
                                                    log.reportSeriesTableID
                                            )?.tableName
                                        }
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            borderRight: '1px solid #E6E4E6',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <span>{log.detail?.message}</span>
                                            {log.detail && log.detail.details && (
                                                <Button
                                                    size="small"
                                                    variant="text"
                                                    onClick={(event) => {
                                                        handleLogDetailPopup(event, log)
                                                    }}
                                                >
                                                    detail
                                                </Button>
                                            )}
                                            <div>
                                                {pending === log.id ? (
                                                    <IconButton
                                                        aria-label="edit"
                                                        sx={{
                                                            borderRadius: '5px',
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                height: '20px',
                                                            }}
                                                            src={XCircle}
                                                            alt={'delete'}
                                                        />
                                                    </IconButton>
                                                ) : (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <IconButton
                                                            aria-label="edit"
                                                            sx={{
                                                                borderRadius: '5px',
                                                            }}
                                                            onClick={(e) => {
                                                                setOpenDrawer({
                                                                    ...logsDetails?.find(
                                                                        (logDetail) =>
                                                                            matchS3key(
                                                                                logDetail.s3Key,
                                                                                log.s3key
                                                                            )
                                                                    ),
                                                                    ...log,
                                                                    tableName: tables?.find(
                                                                        (table) =>
                                                                            table.reportSeriesTableID ===
                                                                            log.reportSeriesTableID
                                                                    )?.tableName,
                                                                    inboxID: inboxID,
                                                                })
                                                            }}
                                                        >
                                                            <img
                                                                style={{
                                                                    height: '20px',
                                                                }}
                                                                src={DatabaseBlack}
                                                                alt={'details'}
                                                            />
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="delete"
                                                            sx={{
                                                                borderRadius: '5px',
                                                            }}
                                                            onClick={() => setDeleteLog(log)}
                                                        >
                                                            <img
                                                                style={{
                                                                    height: '20px',
                                                                }}
                                                                src={Trash}
                                                                alt={'delete'}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {isLoading ? (
                            <ScoopLoader size={72} />
                        ) : (
                            <Typography>No dataset load history</Typography>
                        )}
                    </Box>
                )}
            </Paper>
            <DeleteDialog
                open={deleteLog}
                close={() => setDeleteLog(false)}
                title={'Scoop Log'}
                handleDelete={handleDelete}
                handleCancel={() => setDeleteLog(null)}
                description={
                    'Are you sure you want to delete this Scoop log? You won’t be able to access the data in this log again.'
                }
            />
            <ScoopDrawer
                open={Boolean(openDrawer)}
                onClose={() => setOpenDrawer(null)}
                logDetails={openDrawer}
                dataset={dataset}
                setOpenReprocess={setOpenReprocess}
            />
            <Toast alert={alert} onClose={() => setAlert(null)}>
                {' '}
            </Toast>
            {curLog && (
                <Popover
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handleCloseLogDetailPopup}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {curLog.detail?.details.map((detail, index) => {
                        return (
                            <Typography id={index} sx={{ p: 2 }}>
                                {detail.message}
                            </Typography>
                        )
                    })}
                </Popover>
            )}
        </>
    )
}
export default ScoopHistory
