import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCanvases } from '../../store/actions'
import Dialog from '../common/Dialog/Dialog'
import Button from '../common/Button/Button'
import Input from '../common/Input/Input'
import { useCallback, useEffect, useState } from 'react'
import { useApi } from '../../api/api'
import { InsightClass } from '../Objects/Insight/InsightClass'

export function SaveDialog({ open, setOpen, insight, setInsight, workspaceMetadata, server }) {
    const canvases = useSelector((state) => state.auth.canvases)
    const userID = useSelector((state) => state.auth.userID)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const dispatch = useDispatch()
    const { postData: postCanvasData } = useApi(
        'https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/canvasV2'
    )
    const [saveToCanvas, setSaveToCanvas] = useState('')

    const listCanvas = useCallback(async () => {
        if (!userID || !workspaceID) return
        const action = {
            action: 'listCanvases',
            workspaceID: workspaceID,
            userID: userID,
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
        }
        const result = await postCanvasData(action)
        dispatch(updateCanvases(result))
    }, [postCanvasData, workspaceID, userID])

    useEffect(() => {
        listCanvas()
    }, [workspaceID, userID])

    const handleClose = () => {
        setOpen(false)
    }

    const handleSave = () => {
        setOpen(false)
        server.postData(
            {
                action: 'putObject',
                class: 'scoop.insight.Insight',
                value: insight,
            },
            (result) => {
                if (result.result === 'Object saved') {
                    server.postData(
                        {
                            action: 'getObjects',
                            class: 'scoop.insight.Insight',
                            attributeName: 'workspaceID',
                            attributeValue: server.workspaceID,
                            fields: ['insightKey', 'workspaceID', 'insightName'],
                        },
                        async ({ objects: result }) => {
                            if (saveToCanvas) {
                                const savedInsight = result.filter(
                                    (i) => i.insightName === insight.insightName
                                )[0]
                                const action = {
                                    action: 'loadCanvas',
                                    userID: userID,
                                    workspaceID: workspaceID,
                                    canvasID: saveToCanvas.canvasID,
                                    isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
                                }
                                const resultCanvas = await postCanvasData(action)
                                if (resultCanvas) {
                                    const newInsight = InsightClass.newInsight(
                                        savedInsight.insightName,
                                        {
                                            insightKey: savedInsight.insightKey,
                                            workspaceID: savedInsight.workspaceID,
                                        },
                                        parseFloat(resultCanvas.zoom) || 1
                                    )
                                    if (resultCanvas?.canvasObjects?.length > 0) {
                                        const action = {
                                            action: 'saveCanvas',
                                            userID: userID,
                                            workspaceID: workspaceID,
                                            canvasID: saveToCanvas.canvasID,
                                            canvasName: resultCanvas.canvasName || '',
                                            canvasObjects: [
                                                ...JSON.parse(resultCanvas.canvasObjects),
                                                newInsight,
                                            ],
                                            canvasImage: null,
                                            zoom: resultCanvas.zoom,
                                            presentationID: resultCanvas.presentationID || '',
                                            lastSaved: resultCanvas.lastSaved,
                                            background: resultCanvas.background || '#FFFFFF',
                                            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
                                        }
                                        await postCanvasData(action)
                                    } else {
                                        const action = {
                                            action: 'saveCanvas',
                                            userID: userID,
                                            workspaceID: workspaceID,
                                            canvasID: saveToCanvas.canvasID,
                                            canvasName: resultCanvas.canvasName || '',
                                            canvasObjects: [newInsight],
                                            canvasImage: null,
                                            zoom: resultCanvas.zoom,
                                            presentationID: resultCanvas.presentationID || '',
                                            lastSaved: resultCanvas.lastSaved,
                                            background: resultCanvas.background || '#FFFFFF',
                                            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev',
                                        }
                                        await postCanvasData(action)
                                    }
                                }
                            }
                        }
                    )
                }
            }
        )
    }

    const actions = (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
            <Button onClick={handleClose} text={'Cancel'} className={'primary-button'} />
            <Button
                onClick={handleSave}
                text={'Save'}
                className={'primary-button button-purple'}
                disabled={!insight?.insightName}
            />
        </Box>
    )

    if (workspaceMetadata && insight) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={'800px'}
                title={'Save Insight'}
                actions={actions}
            >
                <Box
                    sx={{
                        width: 500,
                        '& .MuiFormControl-root': {
                            marginTop: '0 !important',
                        },
                        height: `${canvases.length === 0 ? '64px !important' : ''}`,
                    }}
                >
                    <Input
                        id={'insightName'}
                        placeholder={'Insight name'}
                        fullWidth
                        defaultValue={insight.insightName}
                        onChange={(event) => {
                            let newInsight = { ...insight }
                            newInsight.insightName = event.target.value
                            setInsight(newInsight)
                        }}
                        style={{ marginBottom: '16px' }}
                        padding={'8px'}
                    />
                    <FormControl fullWidth>
                        {canvases.length > 0 && (
                            <>
                                <InputLabel>Add insight to canvas</InputLabel>
                                <Select
                                    value={saveToCanvas}
                                    onChange={(event) => setSaveToCanvas(event.target.value)}
                                    label="Add insight to canvas"
                                >
                                    {canvases.map((canvas) => (
                                        <MenuItem key={canvas.canvasName} value={canvas}>
                                            {canvas.canvasName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        )}
                    </FormControl>
                </Box>
            </Dialog>
        )
    } else return null
}
