import React, { useRef, useState } from 'react'
import './FilterItem.css'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    checkboxClasses,
    ListItem,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import PencilSimple from '../../../assets/icons/PencilSimple.svg'
import Trash from '../../../assets/icons/Trash.svg'
import Info from '../../../assets/icons/Info.svg'
import Dialog from '../Dialog/Dialog'
import Divider from '@mui/material/Divider'
import DeleteDialog from '../Dialog/DeleteDialog'
import { Toast } from '../Toast/Toast'
import { EditSavedFilterDialog } from '../../EditSavedFilterDialog/EditSavedFilterDialog'

export const FilterItem = ({ filter, server, getWorkspaceMetadata, isActive }) => {
    const [editOpen, setEditOpen] = useState(false)
    const [infoOpen, setInfoOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [hovered, setHovered] = useState(false)
    const [alert, setAlert] = useState(null)
    const anchorRef = useRef()

    const handleDelete = () => {
        setDeleteOpen(false)
        server.postData(
            {
                action: 'delete',
                savedFilterKey: filter.savedFilterKey,
            },
            (r) => {
                if (r.result === '1 items deleted') {
                    setAlert({ severity: 'success', message: 'Success!' })
                    getWorkspaceMetadata()
                } else {
                    setAlert({ severity: 'error', message: 'Error deleting saved filter.' })
                }
            }
        )
    }

    return (
        <>
            <ListItem
                sx={{
                    padding: '5px 5px 5px 8px',
                    width: '100%',
                    borderRadius: '5px',
                    ':hover': {
                        backgroundColor: '#F9F9F9',
                    },
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        height: '25px',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            width: '85%',
                        }}
                    >
                        {isActive && (
                            <Checkbox
                                sx={{
                                    height: 10,
                                    width: 10,
                                    '&.MuiCheckbox-root': {
                                        color: '#C7C5C7',
                                    },
                                    [`&, &.${checkboxClasses.checked}`]: {
                                        color: '#E50B54',
                                    },
                                }}
                                checked
                                size="medium"
                            />
                        )}
                        <Tooltip title={filter.filterName || ''}>
                            <Typography
                                className={'inter'}
                                sx={{
                                    fontSize: 14,
                                    overflow: 'hidden',
                                    ml: isActive ? '10px' : 0,
                                    textOverflow: 'ellipsis',
                                    fontWeight: 400,
                                    maxWidth: 200,
                                }}
                                noWrap
                            >
                                {filter.filterName}
                            </Typography>
                        </Tooltip>
                    </Box>
                    {(hovered || menuOpen) && (
                        <IconButton
                            ref={anchorRef}
                            onClick={() => setMenuOpen(true)}
                            sx={{
                                padding: '4px',
                                borderRadius: '5px',
                                border: '1px solid #E6E4E6',
                                color: 'inherit',
                            }}
                        >
                            <MoreHorizRoundedIcon fontSize={'small'} />
                        </IconButton>
                    )}
                </Box>
                <Menu
                    open={menuOpen}
                    anchorEl={anchorRef.current}
                    onClose={() => setMenuOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    sx={{
                        fontSize: '14px',
                        width: '520px',
                        transform: 'translateX(30px)',
                    }}
                >
                    <MenuItem
                        key={'info-key'}
                        onClick={() => {
                            setInfoOpen(true)
                            setMenuOpen(false)
                        }}
                        sx={{ fontSize: '14px', width: '140px' }}
                    >
                        <img style={{ paddingRight: 10, height: 20 }} src={Info} alt={'Info'} />
                        {'Info'}
                    </MenuItem>
                    <MenuItem
                        key={'edit-key'}
                        onClick={() => {
                            setEditOpen(true)
                            setMenuOpen(false)
                        }}
                        sx={{ fontSize: '14px', width: '140px' }}
                    >
                        <img
                            style={{ paddingRight: 10, height: 20 }}
                            src={PencilSimple}
                            alt={'Edit'}
                        />
                        {'Edit'}
                    </MenuItem>
                    <MenuItem
                        key={'delete-key'}
                        onClick={() => {
                            setDeleteOpen(true)
                            setMenuOpen(false)
                        }}
                        sx={{ fontSize: '14px', width: '140px' }}
                    >
                        <img style={{ paddingRight: 10, height: 20 }} src={Trash} alt={'Delete'} />
                        {'Delete'}
                    </MenuItem>
                </Menu>
            </ListItem>
            {infoOpen && (
                <Dialog
                    title={'Saved filter info'}
                    open={infoOpen}
                    onClose={() => setInfoOpen(false)}
                    containerStyle={{ width: 500 }}
                >
                    <Box>
                        <Typography className={'inter filter-info-label'}>Filter name</Typography>
                        <Typography className={'inter filter-info-value'}>
                            {filter.filterName}
                        </Typography>
                    </Box>
                    <Divider />
                    {filter.filter.boperator ? (
                        filter.filter.filters.map((f, i) => (
                            <>
                                <Box>
                                    <Typography className={'inter filter-info-label'}>
                                        Filter attribute
                                    </Typography>
                                    <Typography className={'inter filter-info-value'}>
                                        {f.attributeName}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography className={'inter filter-info-label'}>
                                        Filter values
                                    </Typography>
                                    <Typography className={'inter filter-info-value'}>
                                        {f.filterValue.values.join(', ')}
                                    </Typography>
                                </Box>
                                {i < filter.filter.filters.length - 1 && <Divider />}
                            </>
                        ))
                    ) : (
                        <>
                            <Box>
                                <Typography className={'inter filter-info-label'}>
                                    Filter attribute
                                </Typography>
                                <Typography className={'inter filter-info-value'}>
                                    {filter.filter.attributeName}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography className={'inter filter-info-label'}>
                                    Filter values
                                </Typography>
                                <Typography className={'inter filter-info-value'}>
                                    {filter.filter.filterValue.values.join(', ')}
                                </Typography>
                            </Box>
                        </>
                    )}
                    {isActive && (
                        <>
                            <Divider />
                            <Box>
                                <Typography className={'inter filter-info-label'}>
                                    Used by
                                </Typography>
                                <Typography className={'inter filter-info-value'}>
                                    {isActive.metricName}
                                </Typography>
                            </Box>
                        </>
                    )}
                </Dialog>
            )}
            {deleteOpen && (
                <DeleteDialog
                    handleCancel={() => setDeleteOpen(false)}
                    title={'Saved Filter'}
                    description={
                        'Deleting this saved filter will remove it permanently from the workspace'
                    }
                    open={deleteOpen}
                    handleDelete={handleDelete}
                />
            )}
            {editOpen && (
                <EditSavedFilterDialog
                    editOpen={editOpen}
                    setEditOpen={setEditOpen}
                    filter={filter}
                    server={server}
                    getWorkspaceMetadata={getWorkspaceMetadata}
                />
            )}
            {alert && (
                <Toast
                    alert={{
                        message: alert.message,
                        severity: alert.severity,
                    }}
                    onClose={() => setAlert(null)}
                />
            )}
        </>
    )
}
