// uiActions.js
export const TOGGLE_SWITCH_WORKSPACE_MODAL = 'TOGGLE_SWITCH_WORKSPACE_MODAL'
export const TOGGLE_INSIGHT_MODAL = 'TOGGLE_INSIGHT_MODAL'
export const TOGGLE_SOURCE_MODAL = 'TOGGLE_SOURCE_MODAL'
export const SET_RESET_TRIGGER = 'SET_RESET_TRIGGER'
export const TOGGLE_FILE_UPLOAD = 'TOGGLE_FILE_UPLOAD'
export const TOGGLE_FILE_HIDE = 'TOGGLE_FILE_HIDE'
export const TOGGLE_SNAP = 'TOGGLE_SNAP'
export const FORCE_EXPLORER_DATA_REFRESH = 'FORCE_EXPLORER_DATA_REFRESH'
export const ZOOM_IN = 'ZOOM_IN'
export const ZOOM_OUT = 'ZOOM_OUT'
export const SET_ZOOM = 'SET_ZOOM'
export const CAN_SHOW_TOUR_ALERT = 'CAN_SHOW_TOUR_ALERT'
export const SET_PRESENTATION_ID = 'SET_PRESENTATION_ID'
export const SET_LAST_SAVED = 'SET_LAST_SAVED'
export const SET_MAX_SCROLL = 'SET_MAX_SCROLL'
export const SET_ACTIVE_MODE = 'SET_ACTIVE_MODE'
export const CREATE_THUMBNAIL = 'CREATE_THUMBNAIL'
export const SET_SHOW_FRAME_DRAWER = 'SET_SHOW_FRAME_DRAWER'
export const SET_SHOW_EDIT_ELEMENT_WRAPPER = 'SET_SHOW_EDIT_ELEMENT_WRAPPER'
export const SET_BACKGROUND_COLOR = 'SET_BACKGROUND_COLOR'
export const SET_SELECTED_OBJECT = 'SET_SELECTED_OBJECT'
export const SET_PRESENTATION_LOADING = 'SET_PRESENTATION_LOADING'

export const toggleSwitchWorkspaceModal = (payload) => ({
    type: TOGGLE_SWITCH_WORKSPACE_MODAL,
    payload,
})

export const toggleInsightModal = () => ({
    type: TOGGLE_INSIGHT_MODAL,
})

export const toggleSourceModal = () => ({
    type: TOGGLE_SOURCE_MODAL,
})

export const setResetTrigger = () => ({
    type: SET_RESET_TRIGGER,
    payload: true,
})

export const resetResetTrigger = () => ({
    type: SET_RESET_TRIGGER,
    payload: false,
})

export const toggleFileUpload = () => {
    return {
        type: TOGGLE_FILE_UPLOAD,
    }
}

export const toggleFileHide = () => {
    return {
        type: TOGGLE_FILE_HIDE,
    }
}

export const toggleSnap = () => {
    return {
        type: TOGGLE_SNAP,
    }
}

export const createThumbnail = () => {
    return {
        type: CREATE_THUMBNAIL,
    }
}

export const forceExplorerDataRefresh = (worksheetID) => {
    return {
        type: FORCE_EXPLORER_DATA_REFRESH,
        worksheetID: worksheetID,
    }
}

export const zoomIn = () => {
    return {
        type: ZOOM_IN,
    }
}

export const zoomOut = () => {
    return {
        type: ZOOM_OUT,
    }
}

export const setZoom = (zoom) => {
    return {
        type: SET_ZOOM,
        zoom: zoom,
    }
}

export const canShowTourAlert = (show) => {
    return {
        type: CAN_SHOW_TOUR_ALERT,
        show: show,
    }
}

export const setPresentationID = (presentationID) => {
    return {
        type: SET_PRESENTATION_ID,
        presentationID: presentationID,
    }
}

export const setLastSaved = (lastSaved) => {
    return {
        type: SET_LAST_SAVED,
        lastSaved: lastSaved,
    }
}

export const setBackgroundColor = (color) => {
    return {
        type: SET_BACKGROUND_COLOR,
        color: color,
    }
}

export const setMaxScroll = (maxScroll) => {
    return {
        type: SET_MAX_SCROLL,
        maxScroll: maxScroll,
    }
}

export const setActiveMode = (mode) => {
    return {
        type: SET_ACTIVE_MODE,
        mode: mode,
    }
}

export const setShowFrameDrawer = (value) => {
    return {
        type: SET_SHOW_FRAME_DRAWER,
        payload: { value },
    }
}

export const setShowEditElementWrapper = (value) => {
    return {
        type: SET_SHOW_EDIT_ELEMENT_WRAPPER,
        payload: { value },
    }
}

export const setSelectedObject = (id) => {
    return {
        type: SET_SELECTED_OBJECT,
        payload: { id },
    }
}

export const setPresentationLoading = (isLoading) => {
    return {
        type: SET_PRESENTATION_LOADING,
        payload: isLoading,
    }
}
