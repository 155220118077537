import { Box, DialogContentText, List, ListItemButton, ListItemText } from '@mui/material'
import * as React from 'react'
import Dialog from '../common/Dialog/Dialog'
import Button from '../common/Button/Button'

export function OpenDialog({
    open,
    setOpen,
    setInsight,
    workspaceMetadata,
    server,
    setSelectedItemToLoad,
    setAnchor,
    window,
    resetState,
}) {
    let [workspaceInsights, setWorkspaceInsights] = React.useState(null)
    let [selectedItem, setSelectedItem] = React.useState(null)

    function handleClose(event) {
        setOpen(false)
        setWorkspaceInsights(null)
        if (setAnchor) setAnchor(null)
    }

    React.useEffect(() => {
        if (setSelectedItemToLoad) {
            setSelectedItemToLoad(selectedItem)
        }
    }, [selectedItem, setSelectedItemToLoad])

    function handleOpen(event) {
        var index = -1
        for (let i = 0; i < workspaceInsights.length; i++) {
            if (workspaceInsights[i].insightKey === selectedItem) {
                index = i
                break
            }
        }
        if (index >= 0) {
            server.postData(
                {
                    action: 'getObject',
                    class: 'scoop.insight.Insight',
                    key: workspaceInsights[index].insightKey,
                },
                (result) => {
                    setOpen(false)
                    setSelectedItem(null)
                    setWorkspaceInsights(null)
                    if (setAnchor) setAnchor(null)
                    resetState && resetState()
                    let insight
                    insight = JSON.parse(result.savedObject)
                    insight.insightKey = result.insightKey
                    insight.insightName = result.insightName
                    setInsight(insight)
                }
            )
        }
    }

    function handleDelete(event) {
        var index = -1
        for (let i = 0; i < workspaceInsights.length; i++) {
            if (workspaceInsights[i].insightKey === selectedItem) {
                index = i
                break
            }
        }
        if (index >= 0) {
            server.postData(
                {
                    action: 'deleteObject',
                    class: 'scoop.insight.Insight',
                    key: workspaceInsights[index].insightKey,
                },
                (result) => {
                    setInsight(null)
                    setOpen(false)
                    setSelectedItem(null)
                    setWorkspaceInsights(null)
                    if (setAnchor) setAnchor(null)
                }
            )
        }
    }

    if (!workspaceInsights && open) {
        setWorkspaceInsights([])
        setSelectedItem(null)
        server.postData(
            {
                action: 'getObjects',
                class: 'scoop.insight.Insight',
                attributeName: 'workspaceID',
                attributeValue: server.workspaceID,
                fields: ['insightKey', 'workspaceID', 'insightName'],
            },
            ({ objects: result }) => {
                result = result.sort((a, b) => {
                    // Handle null values: consider null less than any string
                    if (a.insightName === null) return 1 // Push nulls to the end
                    if (b.insightName === null) return -1 // Keep non-nulls at the beginning

                    // Case-insensitive comparison for non-null values
                    return a.insightName.toLowerCase().localeCompare(b.insightName.toLowerCase())
                })
                setWorkspaceInsights(result)
            }
        )
    }

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={handleClose} className={'primary-button'} text={'Cancel'} />
            {window && (
                <Button
                    onClick={handleDelete}
                    className={'primary-button'}
                    text={'Delete Insight'}
                />
            )}
            <Button onClick={handleOpen} className={'primary-button button-purple'} text={'Open'} />
        </Box>
    )

    if (workspaceMetadata) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={'800px'}
                title={'Open summary'}
                actions={actions}
            >
                <DialogContentText>Select from summaries saved to Workspace</DialogContentText>
                {workspaceInsights && (
                    <Box
                        sx={{
                            height: 400,
                            minWidth: 700,
                            bgcolor: 'background.paper',
                            mt: 2,
                            border: '0.5px solid #E50B54',
                            borderRadius: '5px',
                            padding: 1,
                            overflow: 'scroll',
                        }}
                    >
                        <List dense={true}>
                            {workspaceInsights?.map((wi) => {
                                return (
                                    <ListItemButton
                                        selected={selectedItem && selectedItem === wi.insightKey}
                                        key={wi.insightKey}
                                        onClick={(event) => {
                                            setSelectedItem(wi.insightKey)
                                        }}
                                    >
                                        <ListItemText primary={wi.insightName} />
                                    </ListItemButton>
                                )
                            })}
                        </List>
                    </Box>
                )}
            </Dialog>
        )
    } else return null
}
