import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ScoopLogo from '../../assets/logo/ScoopLogo.svg'
import SwitchWorkspaceDialog, { DEFAULT_WORKSPACE_NAME } from '../SwitchWorkspaceDialog'
import UserProfileModal from './Profile'
import { navbarItems } from './navbarItems'
import NavbarItem from './NavBarItem'
import UserCircle from '../../assets/icons/UserCircle.svg'
import './NavBar.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CaretUpDown from '../../assets/icons/CaretUpDown.svg'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../store/actions/authActions'
import { ROUTES } from '../../router/routes'
import { useCallback, useEffect, useState } from 'react'
import { useApi } from '../../api/api'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Typography from '@mui/material/Typography'
import { Alert, Collapse, IconButton, Tooltip } from '@mui/material'
import { toggleSwitchWorkspaceModal, canShowTourAlert } from '../../store/actions/uiActions'
import { AlertTitle } from '@mui/lab'
import CloseIcon from '@mui/icons-material/Close'

const NavBar = ({ workspaceName }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [alertOpen, setAlertOpen] = useState(false)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const defaultTourWorkspace = useSelector((state) => state.auth.defaultTourWorkspace)
    const workspacesAccessible = useSelector((state) => state.auth.workspacesAccessible)
    const switchWorkspaceModalOpen = useSelector((state) => state.ui.switchWorkspaceModalOpen)
    const userID = useSelector((state) => state.auth.userID)
    const userInfo = useSelector((state) => state.auth)
    const readyToShowAlert = useSelector((state) => state.ui.canShowTourAlert)
    const { canvasID } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isMenuOpen = Boolean(anchorEl)
    const [showUserProfile, setShowUserProfile] = useState(false)
    const { postData: postDataDefault } = useApi()
    const isGuestMode = useSelector((state) => state.auth.isGuestMode)

    useEffect(() => {
        if (!workspaceID && !isGuestMode && !localStorage.getItem('recipeMapping')) {
            dispatch(toggleSwitchWorkspaceModal(true))
        } else {
            dispatch(toggleSwitchWorkspaceModal(false))
        }
    }, [workspaceID, isGuestMode])

    useEffect(() => {
        let currentWorkspace = workspacesAccessible.find(
            (workspace) => workspace.workspaceID === workspaceID
        )

        if (!readyToShowAlert) return
        if (currentWorkspace) {
            if (defaultTourWorkspace) {
                if (
                    defaultTourWorkspace === currentWorkspace.workspaceID ||
                    currentWorkspace.name === DEFAULT_WORKSPACE_NAME
                ) {
                    setAlertOpen(true)
                } else {
                    alertOpen && setAlertOpen(null)
                    readyToShowAlert && dispatch(canShowTourAlert(false))
                }
            } else {
                if (currentWorkspace.name === DEFAULT_WORKSPACE_NAME) {
                    setAlertOpen(true)
                } else {
                    alertOpen && setAlertOpen(null)
                    readyToShowAlert && dispatch(canShowTourAlert(false))
                }
            }
        } else setAlertOpen(null)
    }, [workspacesAccessible, workspaceID, defaultTourWorkspace, readyToShowAlert])

    useEffect(() => {
        const redirect = localStorage.getItem('redirectUrl')
        if (redirect) {
            navigate(redirect)
            localStorage.removeItem('redirectUrl')
        }
    }, [])

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        dispatch(toggleSwitchWorkspaceModal(false))
    }

    const handleProfileItemClick = () => {
        setAnchorEl(null)
        setShowUserProfile(true)
    }

    const handleLogout = () => {
        dispatch(logout())
        navigate(ROUTES.LOGIN)
    }

    const menuId = 'primary-search-account-menu'

    const retrieveUserInformation = useCallback(async () => {
        if (!userID || isGuestMode) return
        const action = {
            action: 'getObjects',
            attributeName: 'userID',
            attributeValue: userID,
            class: 'scoop.user.User',
        }
        const { objects: result } = await postDataDefault(action)
        if (result && result.length > 0) return result[0]
    }, [postDataDefault, userID])

    const retrieveUserWorkspaces = useCallback(async () => {
        if (!userID || isGuestMode) return
        const action = {
            action: 'getObjects',
            attributeName: 'userID',
            class: 'scoop.workspace.Workspace',
            attributeValue: userID,
            relatedClass: 'scoop.workspace.WorkspaceUser',
        }
        const { objects: result } = await postDataDefault(action)
        if (result && result.length > 0) return result
    }, [postDataDefault, userID])

    useEffect(() => {
        const fetchAndDispatchUserInfo = async () => {
            let userData = await retrieveUserInformation()
            let workspaces = await retrieveUserWorkspaces()
            if (userData && workspaces) {
                dispatch({
                    type: 'UPDATE_USER_INFO',
                    user: {
                        attributes: {
                            accountID: userData.accountID,
                            firstName: userData.firstName,
                            lastName: userData.lastName,
                            googleAccount: userData.googleAccount,
                            workspacesAccessible: workspaces,
                            defaultTourWorkspace: userData.defaultTour,
                            personalWorkspace: userData.personalWorkspace,
                        },
                    },
                })
            }
        }
        fetchAndDispatchUserInfo()
    }, [userID, workspaceID, canvasID])

    const RenderTooltip = ({ children }) => {
        return (
            <>
                <Collapse in={alertOpen}>
                    <Box
                        sx={{
                            position: 'absolute',
                            display: 'inline-block',
                            top: '56px',
                            left: '20px',
                            maxWidth: '450px',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: -6,
                                left: 'calc(50% - 6px)',
                                '&::before': {
                                    content: '""',
                                    display: 'block',
                                    width: 12,
                                    height: 12,
                                    backgroundColor: '#0288d1',
                                    transform: 'rotate(45deg)',
                                },
                            }}
                        />
                        <Alert
                            variant="filled"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        dispatch(canShowTourAlert(false))
                                        setAlertOpen(false)
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            severity="info"
                        >
                            <AlertTitle>Welcome to Scoop!</AlertTitle>
                            Enjoy the Scoop Tour. When you’re done, and ready to use your own data,
                            click here to switch to your own workspace.
                        </Alert>
                    </Box>
                </Collapse>
                <Tooltip title={'Switch Workspace'}>{children}</Tooltip>
            </>
        )
    }

    return (
        <>
            <SwitchWorkspaceDialog
                shouldOpen={switchWorkspaceModalOpen}
                onClose={handleMenuClose}
            />
            <UserProfileModal
                open={showUserProfile}
                userInfo={userInfo}
                handleClose={() => setShowUserProfile(false)}
            />
            {!isGuestMode && (
                <AppBar>
                    <Toolbar className={'navbar'}>
                        <Box className={'scoop-logo-container'}>
                            <img
                                src={ScoopLogo}
                                alt="Scoop Logo"
                                onClick={() => navigate(ROUTES.CANVAS_DASHBOARD)}
                            />
                        </Box>
                        <Box className={'navbar-items-container'}>
                            <RenderTooltip>
                                <Box
                                    onClick={() => dispatch(toggleSwitchWorkspaceModal(true))}
                                    className={'navbar-item'}
                                    sx={{ padding: '0 16px' }}
                                >
                                    <Typography className={'inter'}>{workspaceName}</Typography>
                                    <img src={CaretUpDown} alt="Switch Workspace" />
                                </Box>
                            </RenderTooltip>
                            {navbarItems.map((item, index) => (
                                <NavbarItem
                                    key={index}
                                    icon={item.icon}
                                    label={item.label}
                                    route={item.route}
                                    selected={location.pathname.includes(item.route)}
                                    onClick={() => navigate(item.route)}
                                />
                            ))}
                        </Box>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: 'flex',
                                    gap: '14px',
                                },
                            }}
                        >
                            <a
                                href="https://www.scoopanalytics.com/help"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Box aria-label="show new notifications" className={'navbar-item'}>
                                    <HelpOutlineIcon sx={{ color: 'white', fontWeight: 400 }} />
                                </Box>
                            </a>
                            <Box
                                className={'navbar-item'}
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                sx={{ color: 'white' }}
                            >
                                <img src={UserCircle} alt={'User Circle'} />
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
            )}
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                id={menuId}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={handleMenuClose}
                sx={{ top: 30 }}
            >
                <MenuItem onClick={handleProfileItemClick}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Log out</MenuItem>
            </Menu>
        </>
    )
}
export default NavBar
