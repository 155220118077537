import Dialog from '../../../common/Dialog/Dialog'
import { Box, Typography } from '@mui/material'
import './NewDatasetModal.css'
import SalesforceIcon from '../../../../assets/icons/Salesforce.svg'
import LinkedInIcon from '../../../../assets/icons/LinkedIn.svg'
import GoogleSheets from '../../../../assets/icons/GoogleSheets.svg'
import JiraIcon from '../../../../assets/icons/Jira.svg'
import HubspotIcon from '../../../../assets/icons/Hubspot.svg'
import AirtableIcon from '../../../../assets/icons/Airtable.svg'
import OtherIcon from '../../../../assets/icons/Email.svg'
// import AWS from '../../../../assets/icons/AWS.svg';
import GoogleDataStudioIcon from '../../../../assets/icons/GoogleDataStudio.svg'
import SharetribeIcon from '../../../../assets/icons/Sharetribe.svg'
import DatabaseBlack from '../../../../assets/icons/DatabaseBlack.svg'
import PipedriveIcon from '../../../../assets/icons/PipedriveIcon.png'
import AttioIcon from '../../../../assets/icons/attio.png'
import CopperIcon from '../../../../assets/icons/copper.jpeg'
import GoogleAnalyticsIcon from '../../../../assets/icons/google-analytics.svg'
import CloseIcon from '../../../../assets/icons/close.svg'
import ApolloIcon from '../../../../assets/icons/apollo.svg'
import MondayIcon from '../../../../assets/icons/Monday.png'
import { useEffect, useRef, useState } from 'react'
import { Hubspot } from '../../Appconnect/Hubspot/Hubspot'
import { LinkedIn } from '../../Appconnect/LinkedIn/LinkedIn'
import { GoogleDataStudio } from '../../Appconnect/GoogleDataStudio/GoogleDataStudio'
import { Sharetribe } from '../../Appconnect/Sharetribe/Sharetribe'
import { Apollo } from '../../Appconnect/Apollo/Apollo'
import { Jira } from '../../Appconnect/Jira/Jira'
import { SelectType } from './SelectType'
import { Salesforce } from '../../Appconnect/GMail/Salesforce'
import { Other } from '../../Appconnect/GMail/Other'
import { Database } from '../../Appconnect/Database/Database'
import datasourceDefs from '../../../../api/datasourceConfig'
import { SelectAPIorReport } from './SelectAPIorReport'
import { APIConnector } from '../../Appconnect/API/APIConnector'

// not yet active
import CrystalReportsIcon from '../../../../assets/icons/CrystalReports.png'
import GongIcon from '../../../../assets/icons/GongIcon.png'
import MarketoIcon from '../../../../assets/icons/MarketoIcon.png'
import MetaIcon from '../../../../assets/icons/MetaIcon.svg'
import MicrosoftDynamicsIcon from '../../../../assets/icons/MicrosoftDynamics.png'
import PowerBIIcon from '../../../../assets/icons/PowerBI.png'
import MicrosoftSSRSIcon from '../../../../assets/icons/MicrosoftSSRS.svg'
import MLSIcon from '../../../../assets/icons/MLS.png'
import NetsuiteIcon from '../../../../assets/icons/Netsuite.svg'
import OutreachIcon from '../../../../assets/icons/Outreach.png'
import OracleBIIcon from '../../../../assets/icons/OracleBI.svg'
import QuickbooksIcon from '../../../../assets/icons/Quickbooks.svg'
import WorkdayIcon from '../../../../assets/icons/Workday.png'
import SproutSocialIcon from '../../../../assets/icons/SproutSocial.png'
import TableauIcon from '../../../../assets/icons/Tableau.svg'
import YardiIcon from '../../../../assets/icons/Yardi.png'
import ZendeskIcon from '../../../../assets/icons/Zendesk.svg'

const renderComingSoon = () => {
    return (
        <Typography
            sx={{
                paddingBottom: '1px',
                paddingRight: '4px',
                fontSize: '10px',
                position: 'absolute',
                top: '2px',
                right: '1px',
                color: '#E50B54',
                fontFamily: 'Inter',
                fontStyle: 'normal',
            }}
        >
            {/* Coming Soon! */}
        </Typography>
    )
}

const sources = [
    {
        name: 'Salesforce',
        icon: SalesforceIcon,
        backgroundColor: '#00A1E00D',
        step: 3,
        sourceValue: 'Salesforce',
    },
    {
        name: 'Google Sheets',
        icon: GoogleSheets,
        backgroundColor: '#21A4640D',
        step: 1,
        sourceValue: 'GoogleSheets',
    },
    {
        name: 'HubSpot',
        icon: HubspotIcon,
        backgroundColor: '#FF7A590D',
        step: 3,
        sourceValue: 'HubSpot',
    },
    {
        name: 'Pipedrive',
        icon: PipedriveIcon,
        backgroundColor: '#21A4640D',
        step: 3,
        sourceValue: 'Pipedrive',
    },
    {
        name: 'Attio',
        icon: AttioIcon,
        backgroundColor: '#21A4640D',
        step: 2,
        sourceValue: 'Attio',
    },
    {
        name: 'Copper',
        icon: CopperIcon,
        backgroundColor: '#21A4640D',
        step: 2,
        sourceValue: 'Copper',
    },
    {
        name: 'Close',
        icon: CloseIcon,
        backgroundColor: '#FF7A590D',
        step: 2,
        sourceValue: 'Close',
    },
    {
        name: 'Monday',
        icon: MondayIcon,
        backgroundColor: '#FF7A590D',
        step: 2,
        sourceValue: 'Monday',
    },
    {
        name: 'LinkedIn',
        icon: LinkedInIcon,
        backgroundColor: '#FF7A590D',
        step: 1,
        sourceValue: 'LinkedIn',
    },
    {
        name: 'Airtable',
        icon: AirtableIcon,
        backgroundColor: '#0000000D',
        step: 2,
        sourceValue: 'Airtable',
    },
    {
        name: 'Database',
        icon: DatabaseBlack,
        backgroundColor: '#0000000D',
        step: 1,
        sourceValue: 'Database',
    },
    {
        name: 'Google Analytics',
        icon: GoogleAnalyticsIcon,
        backgroundColor: 'rgba(38, 132, 255, 0.05)',
        step: 2,
        sourceValue: 'GoogleAnalytics',
    },
    {
        name: 'Google Data Studio',
        icon: GoogleDataStudioIcon,
        backgroundColor: 'rgba(38, 132, 255, 0.05)',
        step: 1,
        sourceValue: 'GoogleDataStudio',
    },
    {
        name: 'Apollo',
        icon: ApolloIcon,
        backgroundColor: 'rgba(38, 132, 255, 0.05)',
        step: 3,
        sourceValue: 'Apollo',
    },
    {
        name: 'Quickbooks',
        icon: QuickbooksIcon,
        backgroundColor: '#00A1E00D',
        step: 2,
        sourceValue: 'Quickbooks',
        disabled: false,
    },
    {
        name: 'Sharetribe',
        icon: SharetribeIcon,
        backgroundColor: 'rgba(38, 132, 255, 0.05)',
        step: 1,
        sourceValue: 'Sharetribe',
    },
    {
        name: 'Jira',
        icon: JiraIcon,
        backgroundColor: '#0000000D',
        step: 1,
        sourceValue: 'Jira',
    },
    {
        name: 'Other',
        icon: OtherIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Other',
    },
    {
        name: 'Crystal Reports',
        icon: CrystalReportsIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'CrystalReports',
        disabled: true,
    },
    {
        name: 'Gong',
        icon: GongIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Gong',
        disabled: true,
    },
    {
        name: 'Marketo',
        icon: MarketoIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Marketo',
        disabled: true,
    },
    {
        name: 'Meta (Facebook Analytics)',
        icon: MetaIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Meta',
        disabled: true,
    },
    {
        name: 'Microsoft Dynamics',
        icon: MicrosoftDynamicsIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'MicrosoftDynamics',
        disabled: true,
    },
    {
        name: 'Power BI',
        icon: PowerBIIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'PowerBI',
        disabled: true,
    },
    {
        name: 'Microsoft SSRS',
        icon: MicrosoftSSRSIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'MicrosoftSSRS',
        disabled: true,
    },
    {
        name: 'MLS Realtor',
        icon: MLSIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'MLS',
        disabled: true,
    },
    {
        name: 'Netsuite',
        icon: NetsuiteIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Netsuite',
        disabled: true,
    },
    {
        name: 'Outreach',
        icon: OutreachIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Outreach',
        disabled: true,
    },
    {
        name: 'Oracle BI',
        icon: OracleBIIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'OracleBI',
        disabled: true,
    },
    {
        name: 'Workday',
        icon: WorkdayIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Workday',
        disabled: true,
    },
    {
        name: 'Sprout Social',
        icon: SproutSocialIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'SproutSocial',
        disabled: true,
    },
    {
        name: 'Tableau',
        icon: TableauIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Tableau',
        disabled: true,
    },
    {
        name: 'Yardi',
        icon: YardiIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Yardi',
        disabled: true,
    },
    {
        name: 'Zendesk',
        icon: ZendeskIcon,
        backgroundColor: '#00A1E00D',
        step: 1,
        sourceValue: 'Zendesk',
        disabled: true,
    },
]

export const ConnectDatasource = ({ onClose, setAlert }) => {
    const [source, setSource] = useState('')
    const [type, setType] = useState('')
    const [step, setStep] = useState(0)
    const [isSnapshotDefault, setIsSnapshotDefault] = useState(false)
    const [basicConfiguration, setBasicConfiguration] = useState(null)
    const [isAPI, setIsAPI] = useState(false)
    const [visibleSources, setVisibleSources] = useState([])

    useEffect(() => {
        if (step === 0 && visibleSources.length === 0) {
            setVisibleSources(sources)
        } else {
            setVisibleSources([])
        }
    }, [step])

    useEffect(() => {
        datasourceDefs.find((f) => f.name === source)?.isSnapshotDefault
            ? setIsSnapshotDefault(true)
            : setIsSnapshotDefault(false)
        // console.log(datasourceDefs.find(f => f.name === source), source)
    }, [source])

    const handleContinue = (data) => {
        setBasicConfiguration(data)
        setStep(2)
    }

    const renderSource = () => {
        switch (source) {
            case 'HubSpot':
                if (isAPI) {
                    return (
                        <APIConnector
                            selectedInbox={basicConfiguration}
                            setSelectedInbox={setBasicConfiguration}
                            open={true}
                            onClose={onClose}
                            stepBack={() => setStep(3)}
                            type={type}
                            setAlert={setAlert}
                            connectorType="HubSpot"
                            disabled
                        />
                    )
                } else {
                    return (
                        <Hubspot
                            basicConfiguration={basicConfiguration}
                            open={true}
                            onClose={onClose}
                            stepBack={() => setStep(3)}
                            type={type}
                            setAlert={setAlert}
                        />
                    )
                }
            case 'LinkedIn':
                return (
                    <LinkedIn
                        basicConfiguration={basicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(1)}
                        type={type}
                        setAlert={setAlert}
                    />
                )
            case 'GoogleDataStudio':
                return (
                    <GoogleDataStudio
                        basicConfiguration={basicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(1)}
                        type={type}
                        setAlert={setAlert}
                    />
                )
            case 'Salesforce':
                if (isAPI) {
                    return (
                        <APIConnector
                            selectedInbox={basicConfiguration}
                            setSelectedInbox={setBasicConfiguration}
                            open={true}
                            onClose={onClose}
                            stepBack={() => setStep(3)}
                            type={type}
                            setAlert={setAlert}
                            connectorType="Salesforce"
                        />
                    )
                } else {
                    return (
                        <Salesforce
                            basicConfiguration={basicConfiguration}
                            open={true}
                            onClose={onClose}
                            stepBack={() => setStep(1)}
                            type={type}
                            setAlert={setAlert}
                        />
                    )
                }
            case 'Database':
                return (
                    <Database
                        basicConfiguration={basicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(1)}
                        type={type}
                        setAlert={setAlert}
                    />
                )
            case 'Sharetribe':
                return (
                    <Sharetribe
                        basicConfiguration={basicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(1)}
                        type={type}
                        setAlert={setAlert}
                    />
                )
            case 'Pipedrive':
            case 'Airtable':
            case 'GoogleAnalytics':
            case 'Jira':
            case 'Close':
            case 'Monday':
            case 'GoogleSheets':
            case 'Quickbooks':
            case 'Attio':
            case 'Copper':
                return (
                    <APIConnector
                        selectedInbox={basicConfiguration}
                        setSelectedInbox={setBasicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(0)}
                        type={type}
                        setAlert={setAlert}
                        connectorType={source}
                    />
                )
            case 'Apollo':
                if (isAPI) {
                    return (
                        <APIConnector
                            selectedInbox={basicConfiguration}
                            setSelectedInbox={setBasicConfiguration}
                            open={true}
                            onClose={onClose}
                            stepBack={() => setStep(3)}
                            type={type}
                            setAlert={setAlert}
                            connectorType="Apollo"
                        />
                    )
                } else {
                    return (
                        <Apollo
                            selectedInbox={basicConfiguration}
                            open={true}
                            onClose={onClose}
                            stepBack={() => setStep(1)}
                            type={type}
                            setAlert={setAlert}
                            connectorType="Apollo"
                        />
                    )
                }
            case 'Other':
                return (
                    <Other
                        basicConfiguration={basicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(1)}
                        type={type}
                        setAlert={setAlert}
                    />
                )

            default:
                return renderSourceSelection()
        }
    }

    const renderSourceSelection = () => {
        return (
            <Dialog open={true} onClose={onClose} title={'Dataset from Source'}>
                <Typography sx={{ fontSize: '14px', color: '#635566' }}>
                    Select the datasource you want to Scoop data from
                </Typography>
                <Box className={'sources-grid-container'}>
                    {visibleSources &&
                        visibleSources.map((source, index) => (
                            <Box
                                key={index}
                                className={`source-box ${source.disabled ? 'source-box-disabled' : ''}`}
                                onClick={() => {
                                    // Prevent function execution if the item is disabled
                                    if (!source.disabled) {
                                        setStep(source.step)
                                        setSource(source.sourceValue)
                                    }
                                }}
                                style={{
                                    // Apply opacity and cursor style only if the item is disabled
                                    opacity: source.disabled ? 0.5 : 1,
                                    cursor: source.disabled ? 'not-allowed' : 'pointer',
                                }}
                            >
                                <img
                                    src={source.icon}
                                    alt={source.name}
                                    className={`source-icon ${source.disabled ? 'icon-disabled' : ''}`}
                                />
                                <Typography sx={{ fontSize: '14px' }}>{source.name}</Typography>
                            </Box>
                        ))}

                    <div style={{ height: '1px' }} />
                </Box>
            </Dialog>
        )
    }

    const renderStepContent = () => {
        switch (step) {
            case 0:
                return renderSourceSelection()
            case 1:
                return (
                    <SelectType
                        handleContinue={handleContinue}
                        index={1}
                        setType={setType}
                        step={step}
                        setStep={setStep}
                        onClose={onClose}
                        isSnapshotDefault={isSnapshotDefault}
                        continueButton={'Continue'}
                    />
                )
            case 2:
                return renderSource()
            case 3:
                return (
                    <SelectAPIorReport
                        index={3}
                        setType={setType}
                        setStep={setStep}
                        onClose={onClose}
                        handleContinue={(active) => {
                            if (active === 'Report Data Source') {
                                setIsAPI(false)
                                setStep(1)
                            } else if (active === 'API Data Source') {
                                setIsAPI(true)
                                setStep(2)
                            }
                        }}
                        sourceName={source}
                    />
                )
            default:
                return renderSourceSelection()
        }
    }

    return <>{renderStepContent()}</>
}
