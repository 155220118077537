import './DatasetIndex.css'
import { Box, DialogContent, Grid, Menu, MenuItem, Typography } from '@mui/material'
import SearchBar from '../../../common/SeacrhBar/SearchBar'
import Button from '../../../common/Button/Button'
import { DatasetRow } from './DatasetRow'
import PlusWhite from '../../../../assets/icons/PlusWhite.svg'
import ArrowsCounterClockwise from '../../../../assets/icons/ArrowsCounterClockwise.svg'
import TrashRed from '../../../../assets/icons/TrashRed.svg'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useWorkspaceMetadata } from '../../../../hooks/useGetWorkspaceMetadata'
import { Server } from '../../../../api/Server'
import { CalculatedColumnsDialog } from '../../../Source/CalculatedColumnsDialog'
import { NewDatasetModal } from '../NewDatasetModal/NewDatasetModal'
import { Toast } from '../../../common/Toast/Toast'
import { CalculatedSourceDialog } from '../../../Source/CalculatedSourceDialog'
import { ServerContext } from '../../../Source/SheetState'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'
import PencilSimple from '../../../../assets/icons/PencilSimple.svg'
import Download from '../../../../assets/icons/Download.svg'
import Upload from '../../../../assets/icons/UploadGrey.svg'
import { APIConnector } from '../../Appconnect/API/APIConnector'
import { APIWriteBack } from '../../Appconnect/API/APIWriteBack'
import Dialog from '../../../common/Dialog/Dialog'
import { TourAlertDialog } from '../../../common/Dialog/TourAlertDialog'

export const DatasetIndex = (props) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [selectedInbox, setSelectedInbox] = useState(null)
    const userID = useSelector((state) => state.auth.userID)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const token = useSelector((state) => state.auth.token)
    const accountID = useSelector((state) => state.auth.accountID)
    const {
        metadata,
        isLoading,
        error,
        refresh: getMetadata,
    } = useWorkspaceMetadata(userID, workspaceID, token)
    const [processStatus, setProcessStatus] = useState(new Map())
    const [cfwDialogOpen, setCfwDialogOpen] = useState(false)
    const [openCS, setOpenCS] = useState(false)
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
    const [confirmDeleteConnectionOpen, setConfirmDeleteConnectionOpen] = useState(false)
    const [openDatasetModal, setOpenDatasetModal] = useState(false)
    const [openAlertModal, setOpenAlertModal] = useState(false)
    const [deleteInbox, setDeleteInbox] = useState(null)
    const [alert, setAlert] = useState(null)
    const [reprocessing, setReprocessing] = useState(false)
    const [reprocessId, setReprocessId] = useState('')
    const [apiOpen, setAPIOpen] = useState(false)
    const [writebackOpen, setWritebackOpen] = useState(false)
    const [connectionsOpen, setConnectionsOpen] = useState(false)
    const [pickedConnection, setPickedConnection] = useState(null)

    const processStatusResult = (result) => {
        const psMap = new Map()
        if (result.inboxStatus.length > 0) {
            result.inboxStatus.forEach((inboxStatusItem) => {
                psMap.set(inboxStatusItem.inboxID, inboxStatusItem)
            })
            setProcessStatus(psMap)
            setReprocessing(true)
        } else {
            setProcessStatus(new Map())
            setReprocessing(false)
        }
    }

    const checkStatus = () => {
        if (token) {
            const server = new Server(workspaceID, userID, token)
            server.postData(
                {
                    action: 'getProcessStatus',
                    workspaceID: workspaceID,
                },
                processStatusResult
            )
        }
    }

    useEffect(() => checkStatus(), [token, userID, workspaceID])

    useEffect(() => {
        if (reprocessing) {
            const intervalId = setInterval(checkStatus, 5000)
            setReprocessId(intervalId)
        } else {
            clearInterval(reprocessId)
        }
    }, [reprocessing])

    const handleReprocess = () => {
        const server = new Server(workspaceID, userID, token)
        setAnchorEl(null)
        server.sheetPostData(
            {
                action: 'reProcess',
                inboxID: selectedInbox.inboxID,
            },
            () => {
                checkStatus()
                setAlert({
                    message: 'Processing started. Stay tuned.',
                    severity: 'info',
                })
            }
        )
        handleClose()
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleDeleteInbox = () => {
        setAnchorEl(null)
        setDeleteInbox(selectedInbox.inboxID)
        setConfirmDeleteOpen(true)
        handleClose()
    }

    const handleDelete = () => {
        const server = new Server(workspaceID, userID, token)
        setConfirmDeleteOpen(false)
        if (deleteInbox) {
            server.postData(
                {
                    action: 'delete',
                    inboxID: deleteInbox,
                    deleteAll: true,
                },
                (response) => handleAfterDelete(response),
                {},
                (response) => handleError(response)
            )
        }
        setDeleteInbox(null)
    }

    const handleDeleteConnection = () => {
        const server = new Server(workspaceID, userID, token)
        setConfirmDeleteConnectionOpen(false)
        server.postData(
            {
                action: 'deleteObject',
                class: 'scoop.connector.APIConnection',
                key: pickedConnection.connectionKey,
            },
            (response) => handleAfterDeleteConnection(response),
            {},
            (response) => handleError(response)
        )
    }
    const handleAfterDeleteConnection = (response) => {
        setAlert({
            message: response.message ?? 'Connection deleted successfully',
            severity: 'success',
        })
        getMetadata()
    }

    const handleExtract = (inboxID) => {
        const server = new Server(workspaceID, userID, token)
        server.sheetPostData(
            {
                action: 'runAPIExtract',
                inboxID: inboxID,
            },
            (response) => {
                checkStatus()
            }
        )
        handleClose()
    }

    const handleAfterDelete = (response) => {
        setAlert({
            message: response.message ?? 'Dataset deleted successfully',
            severity: 'success',
        })
        getMetadata()
    }

    const handleError = (response) => {
        setAlert({
            message: response.message ?? 'There was an error deleting the Dataset',
            severity: 'error',
        })
    }

    const handleNewDataset = () => {
        const { workspaceName } = metadata
        if (workspaceName && workspaceName.includes('Tour') && accountID !== 'A1') {
            setOpenAlertModal(true)
        } else {
            setOpenDatasetModal(true)
        }
    }

    let hasWriteback = false
    if (metadata && metadata.apiConnections) {
        for (let i = 0; i < metadata.apiConnections.length; i++) {
            if (metadata.apiConnections[i].supportsWriteback) {
                hasWriteback = true
            }
        }
    }

    return (
        <>
            <div className={'dataset-index-container'}>
                <div className={'dataset-topbar'}>
                    <Typography className={'title-container'}>Datasets</Typography>
                    <SearchBar style={{ width: '75%' }} />
                    {metadata && metadata.apiConnections && metadata.apiConnections.length > 0 && (
                        <Button
                            style={{
                                width: 'fit-content',
                                whiteSpace: 'nowrap',
                                minWidth: 'fit-content',
                                minHeight: '40px',
                                fontSize: '14px !important',
                                padding: '10px 16px',
                            }}
                            onClick={() => {
                                setConnectionsOpen(true)
                            }}
                        >
                            Connections
                        </Button>
                    )}
                    <Button
                        className={'small button-purple'}
                        style={{
                            width: 'fit-content',
                            whiteSpace: 'nowrap',
                            minWidth: 'fit-content',
                            minHeight: '40px',
                            fontSize: '14px !important',
                            padding: '10px 16px',
                        }}
                        onClick={handleNewDataset}
                    >
                        {' '}
                        <img src={PlusWhite} alt={'plus'} style={{ color: 'white' }}></img> New
                        Dataset
                    </Button>
                </div>
                <div className={'dataset-list-container'}>
                    {metadata?.inboxes?.length > 0 ? (
                        metadata.inboxes.map((inbox, index) => (
                            <DatasetRow
                                key={'row-' + index}
                                id={index}
                                dataset={inbox}
                                setAnchorEl={setAnchorEl}
                                setSelectedInbox={setSelectedInbox}
                                processStatus={processStatus}
                            />
                        ))
                    ) : (
                        <Box
                            sx={{
                                height: '100%',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {isLoading ? (
                                <ScoopLoader size={72} />
                            ) : (
                                <Typography>No datasets found</Typography>
                            )}
                        </Box>
                    )}
                </div>
            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{ minWidth: '200px' }}
            >
                <MenuItem onClick={handleReprocess} style={{ gap: '8px', fontSize: '14px' }}>
                    <img src={ArrowsCounterClockwise} alt={'reprocess'} />
                    {'Reprocess data'}
                </MenuItem>
                {selectedInbox && selectedInbox.extractDefinition && (
                    <MenuItem
                        onClick={() => {
                            setAPIOpen(true)
                            setAnchorEl(null)
                            setSelectedInbox({ ...selectedInbox })
                        }}
                        style={{ gap: '8px', fontSize: '14px' }}
                    >
                        {' '}
                        <img src={PencilSimple} alt={'modifyapi'} />
                        {'Modify API Connection'}{' '}
                    </MenuItem>
                )}
                {selectedInbox && selectedInbox.extractDefinition && (
                    <MenuItem
                        onClick={() => {
                            handleExtract(selectedInbox.inboxID)
                        }}
                        style={{ gap: '8px', fontSize: '14px' }}
                    >
                        {' '}
                        <img src={Download} alt={'runextract'} />
                        {'Run Extract Now'}{' '}
                    </MenuItem>
                )}
                {hasWriteback && (
                    <MenuItem
                        onClick={() => {
                            setWritebackOpen(true)
                            setAnchorEl(null)
                        }}
                        style={{ gap: '8px', fontSize: '14px' }}
                    >
                        {' '}
                        <img src={Upload} alt={'writeback'} style={{ width: '24px' }} />
                        {(selectedInbox && selectedInbox.writebackDefinition ? 'Modify' : 'Setup') +
                            ' Application Writeback'}
                    </MenuItem>
                )}
                <MenuItem
                    onClick={handleDeleteInbox}
                    style={{ gap: '8px', color: '#EB382A', fontSize: '14px' }}
                >
                    {' '}
                    <img src={TrashRed} alt={'trash'} />
                    {'Delete Dataset'}{' '}
                </MenuItem>
            </Menu>
            <CalculatedColumnsDialog
                open={cfwDialogOpen}
                setOpen={setCfwDialogOpen}
                inboxID={selectedInbox?.inboxID}
            />
            <NewDatasetModal
                open={openDatasetModal}
                onClose={() => {
                    setOpenDatasetModal(false)
                    getMetadata()
                    checkStatus()
                }}
                setAlert={setAlert}
                getMetadata={getMetadata}
            />
            <Toast alert={alert} onClose={() => setAlert(null)} />
            <CalculatedSourceDialog
                open={openCS}
                setOpen={setOpenCS}
                serverContext={new ServerContext(new Server(workspaceID, userID, token))}
                inbox={selectedInbox}
            />
            <APIConnector
                selectedInbox={selectedInbox}
                setSelectedInbox={setSelectedInbox}
                open={apiOpen}
                onClose={() => {
                    setAPIOpen(false)
                }}
                stepBack={() => {
                    setAPIOpen(false)
                }}
                connectorType={selectedInbox?.extractDefinition?.connectorType}
            />
            <APIWriteBack
                selectedInbox={selectedInbox}
                setSelectedInbox={setSelectedInbox}
                metadata={metadata}
                open={writebackOpen}
                stepBack={() => {
                    setWritebackOpen(false)
                }}
                onClose={() => {
                    setWritebackOpen(false)
                }}
            />
            <Dialog
                open={confirmDeleteOpen}
                onClose={() => setConfirmDeleteOpen(false)}
                actions={[
                    <Button className={'small'} onClick={() => setConfirmDeleteOpen(false)}>
                        Cancel
                    </Button>,
                    <Button
                        className={'button-purple small'}
                        onClick={() => {
                            handleDelete()
                        }}
                    >
                        Confirm
                    </Button>,
                ]}
                title={'Are you sure?'}
            >
                <Typography className={'inter'}>
                    Are you sure that you want to delete this dataset from Scoop?
                </Typography>
            </Dialog>
            <Dialog
                open={confirmDeleteConnectionOpen}
                onClose={() => setConfirmDeleteConnectionOpen(false)}
                actions={[
                    <Button className={'small'} onClick={() => setConfirmDeleteOpen(false)}>
                        Cancel
                    </Button>,
                    <Button
                        className={'button-purple small'}
                        onClick={() => {
                            handleDeleteConnection()
                            setConnectionsOpen(false)
                        }}
                    >
                        Confirm
                    </Button>,
                ]}
                title={'Are you sure?'}
            >
                <Typography className={'inter'}>
                    Are you sure that you want to delete this connection from Scoop?
                </Typography>
            </Dialog>
            <Dialog
                open={connectionsOpen}
                onClose={() => setConnectionsOpen(false)}
                actions={[
                    <Button
                        className={'button-purple small'}
                        onClick={() => {
                            setConnectionsOpen(false)
                        }}
                    >
                        Close
                    </Button>,
                ]}
                title={'Existing Application Connections  '}
            >
                <DialogContent>
                    <Typography className={'inter'} sx={{ mb: 2 }}>
                        <strong>Established connections</strong> (click delete to remove)
                    </Typography>
                    {metadata && metadata.apiConnections && (
                        <Grid container spacing={0} sx={{ alignItems: 'center', flexGrow: 1 }}>
                            {metadata.apiConnections.map((connection) => {
                                return (
                                    <>
                                        <Grid item xs={9}>
                                            {connection.connectionType}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button
                                                onClick={() => {
                                                    setConfirmDeleteConnectionOpen(true)
                                                    setPickedConnection(connection)
                                                }}
                                            >
                                                <img src={TrashRed} alt="delete" />
                                            </Button>
                                        </Grid>
                                    </>
                                )
                            })}
                        </Grid>
                    )}
                </DialogContent>
            </Dialog>
            <TourAlertDialog open={openAlertModal} setOpen={setOpenAlertModal} item={'dataset'} />
        </>
    )
}
