import { useState, useEffect, useCallback } from 'react'
import { Server } from '../api/Server'

// Define the hook as a function
export const useWorkspaceMetadata = (userID, workspaceID, token) => {
    const [metadata, setMetadata] = useState({ inboxes: [] })
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    // Function to fetch metadata
    const fetchMetadata = useCallback(async () => {
        if (!userID || !workspaceID || !token) return
        setIsLoading(true)
        const server = new Server(workspaceID, userID, token)

        try {
            const result = await server.postData(
                {
                    action: 'getWorkspaceMetadata',
                    workspaceID: workspaceID,
                    userID: userID,
                    tableStats: true,
                },
                (result) => {
                    setMetadata(result)
                }
            )
        } catch (err) {
            setError(err)
        } finally {
            setIsLoading(false)
        }
    }, [userID, workspaceID, token])

    // Effect to fetch metadata on mount and when dependencies change
    useEffect(() => {
        fetchMetadata()
    }, [fetchMetadata, userID, workspaceID, token])

    return { metadata, isLoading, error, refresh: fetchMetadata }
}
