import React from 'react'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'
import './SearchBar.css'
import { createTheme } from '@mui/material'

const theme = createTheme({
    palette: {
        primary: {
            main: '#E50B54', // Use the custom color for the primary color
        },
    },
})

const CustomSearchBar = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
        backgroundColor: theme.palette.common.white,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#e2e2e1', // Default border color
        },
        '&:hover fieldset': {
            borderColor: '#E50B54', // Border color on hover
        },
        '&.Mui-focused fieldset': {
            borderColor: '#E50B54', // Border color on focus
        },
    },
    '& .MuiTextField-root': {
        width: '100%',
        margin: theme.spacing(1),
    },
    '& .MuiInputBase-input': {
        borderRadius: '4px',
        backgroundColor: theme.palette.common.white,
        padding: '10px 12px',
        textAlign: 'left',
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
    },
    '& .MuiFormControl-root': {
        margin: '0 !important',
    },
}))

export default function SearchBar({ placeholder, ...props }) {
    return (
        <CustomSearchBar {...props}>
            <TextField
                variant="outlined"
                placeholder={placeholder ? placeholder : 'Search Datasets'}
                InputProps={{
                    endAdornment: <SearchIcon />,
                }}
            />
        </CustomSearchBar>
    )
}
