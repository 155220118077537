import React from 'react'
import './ThemeItem.css'
import { Box, IconButton, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import TrashRed from '../../../../../../assets/icons/TrashRed.svg'
import ReactECharts from 'echarts-for-react'
import { CONFIG_OPTIONS, simpleOption } from '../styleConsts'
import { ScoopTheme } from '../../../../../Insights/Style'
import Button from '../../../../../common/Button/Button'

export const ThemeItem = ({ handleDelete, theme, onClick, selectedTheme, setSelectedProp }) => {
    console.log(theme)
    return (
        <Box
            className={`theme-item-container 
                ${onClick ? 'theme-clickable' : ''}
                ${onClick && selectedTheme === theme?.themeID ? 'preview-theme' : ''}
                ${theme?.colorScheme.backgroundColor === '#00000000' ? (theme?.colorScheme.darkTheme ? 'transparent-dark' : 'transparent-light') : ''}`}
            onClick={() => onClick && onClick(theme.themeID)}
        >
            <Box className={'theme-item-header'}>
                <Tooltip title={theme ? 'Canvas: ' + theme.canvasName : ''}>
                    <Typography className={'inter'} fontSize={14} noWrap={true}>
                        {!theme?.themeName || theme?.themeName === 'Default theme'
                            ? 'Default theme'
                            : theme?.themeName + ' (' + theme?.canvasName + ')' || 'Default theme'}
                    </Typography>
                </Tooltip>
                <Box className={'theme-item-controls'}>
                    {onClick &&
                        (selectedTheme === theme?.themeID ? (
                            <Button
                                className={'button-purple apply-theme-button'}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setSelectedProp(CONFIG_OPTIONS.MAIN)
                                }}
                            >
                                Apply
                            </Button>
                        ) : (
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleDelete(theme.themeID)
                                }}
                            >
                                <img src={TrashRed} alt={'delete'} width={18} height={18} />
                            </IconButton>
                        ))}
                </Box>
            </Box>
            <Box className={'theme-item-preview'}>
                <ReactECharts
                    option={{
                        ...simpleOption,
                        backgroundColor:
                            theme?.colorScheme.backgroundColor || ScoopTheme.backgroundColor,
                        color: theme?.colorScheme.colors.map((c) => c.val) || ScoopTheme.color,
                    }}
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
            </Box>
        </Box>
    )
}
