import DialogContentText from '@mui/material/DialogContentText'
import Dialog from './Dialog'
import React from 'react'
import { Box } from '@mui/material'
import Button from '../Button/Button'
import PropTypes from 'prop-types'

const DeleteDialog = ({
    open,
    onClose,
    title,
    handleDelete,
    handleCancel,
    type,
    description,
    ...props
}) => {
    const deleteActions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={handleCancel} text={'Cancel'} className={'primary-button'} />
            <Button
                onClick={handleDelete}
                autoFocus
                text={'Delete'}
                className={'primary-button button-purple'}
            />
        </Box>
    )
    return (
        <Dialog
            id={'delete-dialog'}
            open={open}
            onClose={handleCancel}
            title={`Delete ${title}?`}
            actions={deleteActions}
            {...props}
        >
            <DialogContentText id="alert-dialog-description">
                {description
                    ? description
                    : `Deleting this ${type} will remove it from the canvas.`}
            </DialogContentText>
        </Dialog>
    )
}
DeleteDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    type: PropTypes.string,
}
export default DeleteDialog
