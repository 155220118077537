import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Dialog from '../common/Dialog/Dialog'
import Button from '../common/Button/Button'
import { DataGrid } from '@mui/x-data-grid'
import Input from '../common/Input/Input'
import { cloneDeep } from 'lodash'
import { ScoopLoader } from '../common/Spinner/ScoopLoader'

export const EditSavedFilterDialog = ({
    filter,
    editOpen,
    setEditOpen,
    server,
    getWorkspaceMetadata,
}) => {
    const [selectedCategoryValuesMap, setSelectedCategoryValuesMap] = useState({})
    const [categoryValuesMap, setCategoryValuesMap] = useState({})
    const [categoryValuesLoadingMap, setCategoryValuesLoadingMap] = useState({})

    const resetState = () => {
        setCategoryValuesMap({})
        setCategoryValuesLoadingMap({})
        setEditOpen(false)
    }

    const setLoading = (attributeName, value) => {
        const loadingTemp = { ...categoryValuesLoadingMap }
        loadingTemp[attributeName] = value
        setCategoryValuesLoadingMap(loadingTemp)
    }

    const handleSelectAttribute = (values, f) => {
        const valuesTemp = cloneDeep(selectedCategoryValuesMap)
        valuesTemp[f.attributeName] = values
        setSelectedCategoryValuesMap(valuesTemp)
    }

    const handleAccordionExpand = (expanded, f) => {
        if (expanded && !Object.keys(categoryValuesMap).includes(f.attributeName)) {
            setLoading(f.attributeName, true)
            const action = {
                action: 'getCategoryValues',
                columnName: f.attributeName,
            }
            server.postData(action, (r) => {
                if (r.values) {
                    const mapTemp = cloneDeep(categoryValuesMap)
                    const tempSelected = []
                    mapTemp[f.attributeName] = r.values.map((v) => {
                        if (f.filterValue.values.includes(v)) tempSelected.push(v)
                        return { id: v, value: v }
                    })
                    setCategoryValuesMap(mapTemp)
                    if (tempSelected.length > 0) handleSelectAttribute(tempSelected, f)
                }
                setLoading(f.attributeName, false)
            })
        }
    }

    const getControllers = (f) => {
        if (f.operator === 'Equals' || f.operator === 'NotEquals') {
            return (
                <DataGrid
                    autoHeight
                    columns={[
                        {
                            field: 'value',
                            headerName: 'Value',
                            type: 'string',
                            sortable: false,
                            editable: false,
                            width: '100%',
                            headerAlign: 'center',
                        },
                    ]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                page: 0,
                                pageSize: 20,
                            },
                        },
                    }}
                    rows={categoryValuesMap[f.attributeName] || []}
                    rowHeight={25}
                    checkboxSelection
                    rowSelectionModel={selectedCategoryValuesMap[f.attributeName] || []}
                    onRowSelectionModelChange={(ids) => handleSelectAttribute(ids, f)}
                    sx={{
                        '& .Mui-checked': {
                            color: '#E50B54',
                        },
                    }}
                />
            )
        }
        if (
            f.operator === 'GreaterThan' ||
            f.operator === 'LessThan' ||
            f.operator === 'GreaterThanOrEquals' ||
            f.operator === 'GreaterThanOrEquals'
        ) {
            return (
                <Box sx={{ mb: 3 }}>
                    <Input
                        label={'Value'}
                        type={typeof f.filterValue.values[0] === 'string' ? 'text' : 'number'}
                        sx={{ width: 200, mr: 10 }}
                        value={selectedCategoryValuesMap[f.attributeName][0]}
                        onChange={(e) => handleSelectAttribute([e.target.value], f)}
                    />
                </Box>
            )
        }
        return null
    }

    const renderAccordion = (f) => {
        return (
            <Accordion onChange={(e, expanded) => handleAccordionExpand(expanded, f)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={'inter'}>{f.attributeName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {categoryValuesLoadingMap[f.attributeName] ? (
                        <Box sx={{ width: '100%', display: 'grid', placeContent: 'center' }}>
                            <ScoopLoader />
                        </Box>
                    ) : (
                        getControllers(f)
                    )}
                </AccordionDetails>
            </Accordion>
        )
    }

    const updateFilter = (oldFilter) => {
        const filterTemp = cloneDeep(oldFilter)
        if (Object.keys(selectedCategoryValuesMap).includes(oldFilter.attributeName)) {
            filterTemp.filterValue.values = selectedCategoryValuesMap[oldFilter.attributeName]
        }
        return filterTemp
    }

    const handleSave = () => {
        let filterToSave
        if (filter.filter.boperator) {
            filterToSave = cloneDeep(filter.filter)
            filterToSave.filters = filterToSave.filters.map((f) => updateFilter(f))
        } else {
            filterToSave = updateFilter(filter.filter)
        }
        server.postData(
            {
                action: 'putObject',
                class: 'scoop.queryfilter.SavedFilter',
                value: {
                    savedFilterKey: filter.savedFilterKey,
                    filterName: filter.filterName,
                    workspaceID: server.workspaceID,
                    filter: filterToSave || filter,
                },
            },
            (r) => {
                getWorkspaceMetadata()
            }
        )
        setEditOpen(false)
    }

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={resetState} text={'Cancel'} />
            <Button onClick={handleSave} className={'button-purple'} text={'Save'} />
        </Box>
    )

    return (
        <Dialog
            title={'Edit saved filter'}
            open={editOpen}
            onClose={resetState}
            containerStyle={{ width: 600 }}
            actions={actions}
        >
            {filter.filter.boperator
                ? filter.filter.filters.map((f) => renderAccordion(f))
                : renderAccordion(filter.filter)}
        </Dialog>
    )
}
