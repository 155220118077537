// APIConnector.jsx
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Dialog from '../../../common/Dialog/Dialog'
import { Typography, Box } from '@mui/material'
import './APIConnector.css'
import Button from '../../../common/Button/Button'
import Input from '../../../common/Input/Input'
import { Server } from '../../../../api/Server'
import { APIFields } from './APIFields'
import { DataGrid } from '@mui/x-data-grid'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'
import { SelectType } from '../../Source/NewDatasetModal/SelectType'

export const APIConnector = ({
    open,
    onClose,
    stepBack,
    stepConnected,
    selectedInbox,
    setSelectedInbox,
    connectorType,
    handleConnectOnly,
    setKey,
    RecipeCard,
    loading,
}) => {
    const userID = useSelector((state) => state.auth.userID)
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const token = useSelector((state) => state.auth.token)
    const [server, setServer] = React.useState(new Server(workspaceID, userID, token))
    const [isConnected, setConnected] = React.useState(false)
    const [objectList, setObjectList] = React.useState([])
    const [fieldList, setFieldList] = React.useState(null)
    const [objectGroupList, setObjectGroupList] = React.useState([])
    const [selectedFieldList, setSelectedFieldList] = React.useState([])
    const [selectedObject, setSelectedObject] = React.useState(null)
    const [connectionKey, setConnectionKey] = React.useState(null)
    const [apiKey, setAPIKey] = React.useState(null)
    const [reportName, setReportName] = React.useState('')
    const [APIFeatures, setAPIFeatures] = React.useState(null)
    const [filterMap, setFilterMap] = React.useState(new Map())
    const [selectedObjectGroup, setSelectedObjectGroup] = React.useState(null)
    const [extractNow, setExtractNow] = React.useState(true)
    const [apiStep, setApiStep] = React.useState('start')
    const [isLoading, setIsLoading] = useState(false)
    const [type, setType] = useState(null)
    const [startDate, setStartDate] = React.useState(
        new Date(new Date().getTime() - 180 * 24 * 60 * 60 * 1000)
    )
    const [errorMessage, setErrorMessage] = useState(null)
    const [apiID, setAPIID] = useState(null)
    const [clientID, setClientID] = useState(null)
    const [scopes, setScopes] = useState(null)

    useEffect(() => {
        if (userID && token && workspaceID) setServer(new Server(workspaceID, userID, token))
    }, [userID, token, workspaceID])

    const setFields = (objectList) => {
        if (selectedInbox && selectedInbox.extractDefinition && objectList) {
            for (let i = 0; i < objectList.length; i++) {
                if (objectList[i].name === selectedInbox.extractDefinition.objectName) {
                    setSelectedObject(objectList[i])
                    break
                }
            }
            let newSelectedFields = []
            for (let i = 0; i < selectedInbox.extractDefinition.selectedFields.length; i++) {
                newSelectedFields.push(selectedInbox.extractDefinition.selectedFields[i].name)
            }
            setSelectedFieldList(newSelectedFields)
            if (selectedInbox.extractDefinition.filters) {
                for (let i = 0; i < selectedInbox.extractDefinition.filters.length; i++) {
                    filterMap.set(selectedInbox.extractDefinition.filters[i].fieldName, {
                        operator: selectedInbox.extractDefinition.filters[i].operator,
                        values: selectedInbox.extractDefinition.filters[i].values,
                    })
                }
            } else {
                filterMap.clear()
            }
        }
    }

    useEffect(() => {
        setType(null)
        if (selectedInbox && selectedInbox.extractDefinition && objectList) {
            setReportName(selectedInbox.label)
            setFields(objectList)
        }
    }, [selectedInbox])

    const testConnected = (interval, server) => {
        server.postData(
            {
                action: 'getAPIFeatures',
                connectionType: connectorType,
            },
            (results) => {
                setAPIFeatures(results)
                if (!results.connectionKey) {
                    // No connection found
                    setConnected(false)
                    if (results.apiKey) {
                        setApiStep('apiKey')
                    } else if (results.oauthLink) {
                        setApiStep('oauthLink')
                    }
                } else {
                    if (handleConnectOnly) {
                        // This page is only doing the connection, not the object selection so step back
                        stepConnected()
                        setKey(results.connectionKey)
                    } else {
                        if (results.hasGooglePicker) {
                            setAPIKey(results.apiKey)
                            setAPIID(results.apiID)
                            setClientID(results.clientID)
                            setScopes(results.scopes)
                        } else if (results.hasObjectGroup) {
                            getAPIObjectGroupList(results.connectionKey, results)
                            setApiStep('objectGroup')
                        } else {
                            getAPIObjectList(results.connectionKey, results)
                            setApiStep('fields')
                        }
                        clearInterval(interval)
                        setConnected(true)
                        setIsLoading(true)
                    }
                }
            }
        )
    }

    const getAPIObjectGroupList = (connectionKey, apiFeatures) => {
        server.postData(
            {
                action: 'getAPIObjectGroupList',
                connectorType: connectorType,
                connectionKey: connectionKey,
                workspaceID: workspaceID,
            },
            (results) => {
                if (results.objectGroups) {
                    setConnected(true)
                    setIsLoading(false)
                    let newObjectGroupList = []
                    results.objectGroups.forEach((objectGroup) => {
                        newObjectGroupList.push({
                            id: objectGroup.name,
                            value: objectGroup.label,
                            group: objectGroup,
                        })
                    })
                    setObjectGroupList(newObjectGroupList)
                    setConnectionKey(connectionKey)
                }
            }
        )
    }

    const getAPIObjectList = (connectionKey, apiFeatures, objectGroup) => {
        setErrorMessage(null)
        var action = {
            action: 'getAPIObjectList',
            connectorType: connectorType,
            connectionKey: connectionKey,
            workspaceID: workspaceID,
        }
        if (objectGroup) {
            action.objectGroup = objectGroup
        }
        server.postData(action, (results) => {
            if (results.error) {
                setErrorMessage(results.error)
            } else if (results.objects) {
                setConnected(true)
                let newObjectList = []
                results.objects.forEach((object) => {
                    newObjectList.push(object)
                })
                setObjectList(newObjectList)
                setConnectionKey(connectionKey)
                let selectedObject = newObjectList[0]
                if (selectedInbox && selectedInbox.extractDefinition) {
                    for (let i = 0; i < newObjectList.length; i++) {
                        if (newObjectList[i].name === selectedInbox.extractDefinition.objectName) {
                            selectedObject = newObjectList[i]
                            break
                        }
                    }
                }
                handleObjectSelect(
                    selectedObject,
                    apiFeatures,
                    objectGroup,
                    connectionKey,
                    newObjectList
                )
            }
        })
    }

    useEffect(() => {
        if (!workspaceID || !token || !userID || !connectorType) return
        const newServer = new Server(workspaceID, userID, token)
        setServer(newServer)
        const interval = setInterval(() => {
            if (open) testConnected(interval, newServer)
        }, 3000)
        if (open) testConnected(interval, newServer)
        return () => {
            clearInterval(interval)
        }
    }, [open, userID, token, workspaceID, connectorType])

    function handleObjectSelect(object, apiFeatures, objectGroup, ckey, cobjectList) {
        setErrorMessage(null)
        setSelectedObject(object)
        let action = {
            action: 'getAPIObjectFieldList',
            connectorType: connectorType,
            connectionKey: ckey ? ckey : connectionKey,
            workspaceID: workspaceID,
            object: object,
        }
        if (objectGroup || selectedObjectGroup) {
            action.objectGroup = objectGroup ? objectGroup : selectedObjectGroup.group
        }
        server.postData(action, (results) => {
            if (results.error) {
                setErrorMessage(results.error)
            } else {
                let newFieldList = []
                let newSelectedFieldList = []
                if (results.fields) {
                    for (let i = 0; i < results.fields.length; i++) {
                        results.fields[i].id = results.fields[i].name
                        results.fields[i].value = results.fields[i].label
                        if (
                            apiFeatures &&
                            apiFeatures.supportsFilters &&
                            results.fields[i].supportsFilters
                        ) {
                            results.fields[i].filter = 'filter'
                        }
                        newFieldList.push(results.fields[i])
                        if (results.fields[i].default) {
                            newSelectedFieldList.push(results.fields[i].name)
                        }
                    }
                    setFieldList(newFieldList)
                }
                if (selectedInbox && selectedInbox.extractDefinition) {
                    // Fields already selected
                    setFields(cobjectList ? cobjectList : objectList)
                } else {
                    setSelectedFieldList(newSelectedFieldList)
                }
                setIsLoading(false)
            }
        })
    }

    function handleSaveAPIKey() {
        server.postData(
            {
                action: 'saveAPIKey',
                connectionType: connectorType,
                apiKey: apiKey,
                workspaceID: workspaceID,
            },
            (results) => {
                setAPIKey(apiKey)
            }
        )
    }

    function handleSave() {
        let saveFields = []
        for (let i = 0; i < selectedFieldList.length; i++) {
            for (let j = 0; j < fieldList.length; j++) {
                if (fieldList[j].name === selectedFieldList[i]) {
                    var obj = {
                        name: fieldList[j].name,
                        label: fieldList[j].label,
                        type: fieldList[j].type,
                        fieldType: fieldList[j].fieldType,
                    }
                    saveFields.push(obj)
                    break
                }
            }
        }
        let filters = []
        if (filterMap.size > 0) {
            for (let [key, value] of filterMap) {
                filters.push({
                    fieldName: key,
                    operator: value.operator,
                    values: value.values,
                })
            }
        }
        let extractDefinition = {
            connectorType: connectorType,
            object: selectedObject,
            fields: saveFields,
            filters: filters,
        }
        if (selectedObjectGroup) {
            extractDefinition.objectGroup = selectedObjectGroup.group
        }
        if (startDate && APIFeatures.requireDateRange) {
            extractDefinition.startDate = startDate
        }
        let action = {
            connectionKey: connectionKey,
            workspaceID: workspaceID,
            datasetName: reportName,
            extractDefinition: extractDefinition,
            extractNow: extractNow,
        }
        if (selectedInbox) {
            action.snapshot = selectedInbox.isSnapshot === 'Snapshot'
            action.isMostRecent = selectedInbox.isMostRecent
            action.isIncremental = selectedInbox.isIncremental
            action.isMultipleLoads = selectedInbox.isMultipleLoads
        } else {
            if (type !== null) {
                action.snapshot = type === 'Snapshot'
            }
            if (selectedObject.syncTable) {
                action.isMostRecent = true
            }
        }
        if (selectedInbox && selectedInbox.inboxID) {
            action.action = 'saveAPIInbox'
            action.inboxID = selectedInbox.inboxID
        } else {
            action.action = 'createAPIInbox'
        }
        server.postData(action, (results) => {
            setSelectedInbox({ ...selectedInbox })
        })
        setType(null)
        setErrorMessage(null)
    }

    let objectName = 'Object'
    let hasObjectGroup = false
    let objectGroupName = 'Group'
    if (APIFeatures) {
        objectName = APIFeatures.objectName ? APIFeatures.objectName : objectName
        if (APIFeatures.hasObjectGroup) {
            hasObjectGroup = true
            objectGroupName = APIFeatures.objectGroupName
                ? APIFeatures.objectGroupName
                : objectGroupName
        }
    }

    const renderGooglePicker = () => {
        return (
            <Dialog
                open={open}
                title={'Dataset from ' + APIFeatures?.connectorName + ' ' + objectGroupName}
                onClose={onClose}
                actions={
                    <>
                        <Button className={'button-grey small'} onClick={stepBack}>
                            Back
                        </Button>
                    </>
                }
                maxWidth={1200}
                sx={{ p: 2 }}
            >
                <Box>
                    {' '}
                    // Pepe - fill in the Google Picker stuff here, then move to getObject
                    <Typography sx={{ mt: 2 }}>
                        Select the {objectGroupName} you would like to retrieve
                    </Typography>
                    {errorMessage && <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>}
                </Box>{' '}
            </Dialog>
        )
    }

    const renderObjectGroup = () => {
        return (
            <Dialog
                open={open}
                title={'Dataset from ' + APIFeatures?.connectorName + ' ' + objectGroupName}
                onClose={onClose}
                actions={
                    <>
                        <Button className={'button-grey small'} onClick={stepBack}>
                            Back
                        </Button>
                    </>
                }
                maxWidth={1200}
                sx={{ p: 2 }}
            >
                <Box>
                    {' '}
                    <Typography sx={{ mt: 2 }}>
                        Select the {objectGroupName} you would like to retrieve
                    </Typography>
                    {errorMessage && <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>}
                    <DataGrid
                        autoHeight
                        columns={[
                            {
                                field: 'value',
                                headerName: 'Value',
                                type: 'string',
                                sortable: true,
                                editable: false,
                                width: 600,
                                headerAlign: 'center',
                            },
                        ]}
                        pageSizeOptions={[15]}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 15 },
                            },
                            sorting: {
                                sortModel: [{ field: 'value', sort: 'asc' }],
                            },
                        }}
                        rows={objectGroupList}
                        rowHeight={25}
                        onRowClick={(event) => {
                            setSelectedObjectGroup(event.row)
                            getAPIObjectList(connectionKey, APIFeatures, event.row.group)
                            setApiStep('fields')
                        }}
                        sx={{
                            '& .Mui-checked': {
                                color: '#E50B54',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                display: 'none',
                            },
                        }}
                    />
                </Box>{' '}
            </Dialog>
        )
    }

    const renderAPIKey = () => {
        return (
            <Dialog
                open={open}
                title={'Dataset from ' + APIFeatures?.connectorName + ' ' + objectName}
                onClose={onClose}
                actions={
                    <>
                        <Button className={'button-grey small'} onClick={stepBack}>
                            Back
                        </Button>
                        <Button className={'button-purple small'} onClick={handleSaveAPIKey}>
                            Save Key
                        </Button>
                    </>
                }
                style={{ width: '500px' }}
            >
                <Box
                    sx={{
                        padding: '12px 16px',
                        fontSize: '14px',
                        background: '#F9F9F9',
                        borderRadius: '5px',
                        color: '#635566',
                    }}
                >
                    {APIFeatures && (
                        <>
                            <Typography>
                                Enter your API Key to connect to {connectorType}
                            </Typography>
                            <Input
                                sx={{ width: 300 }}
                                value={apiKey}
                                onChange={(event) => {
                                    setAPIKey(event.target.value)
                                }}
                            ></Input>{' '}
                        </>
                    )}
                    {!APIFeatures && (
                        <>
                            <Typography>No API Found</Typography>
                        </>
                    )}
                </Box>
            </Dialog>
        )
    }

    const renderOauthLink = () => {
        return (
            <Dialog
                style={{
                    width: RecipeCard ? '900px' : '500px',
                    height: 'auto',
                }}
                maxWidth="lg"
                open={open}
                title={`Dataset from ${(APIFeatures?.connectorName ? APIFeatures.connectorName : connectorType) || ''}`}
                onClose={onClose}
                actions={
                    <>
                        <Button className={'button-grey small'} onClick={stepBack}>
                            Back
                        </Button>
                        {APIFeatures && APIFeatures.oauthLink && (
                            <Button
                                className={'button-purple small'}
                                onClick={() =>
                                    window.open(APIFeatures.oauthLink, '_blank', 'noreferrer')
                                }
                            >
                                Next
                            </Button>
                        )}
                    </>
                }
            >
                <Box
                    sx={{
                        padding: '12px 16px',
                        fontSize: '14px',
                        background: '#F9F9F9',
                        borderRadius: '5px',
                        color: '#635566',
                    }}
                >
                    {loading || !APIFeatures ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <ScoopLoader />
                        </Box>
                    ) : (
                        <>
                            {APIFeatures ? (
                                <>
                                    {RecipeCard ? (
                                        RecipeCard
                                    ) : (
                                        <>
                                            <Typography>
                                                Click the next button below to connect to{' '}
                                                {APIFeatures?.connectorName
                                                    ? APIFeatures.connectorName
                                                    : connectorType}
                                                .
                                            </Typography>
                                        </>
                                    )}
                                </>
                            ) : (
                                <Typography>No API Found</Typography>
                            )}
                        </>
                    )}
                </Box>
            </Dialog>
        )
    }

    const renderLoading = () => {
        return (
            <Dialog
                open={open}
                title={'Dataset from ' + APIFeatures?.connectorName + ' ' + objectName}
                onClose={onClose}
                actions={
                    <>
                        <Button className={'button-grey small'} onClick={stepBack}>
                            Back
                        </Button>
                    </>
                }
                style={{ width: '500px' }}
            >
                <Box
                    sx={{
                        padding: '12px 16px',
                        fontSize: '14px',
                        background: '#F9F9F9',
                        borderRadius: '5px',
                        color: '#635566',
                    }}
                >
                    <ScoopLoader></ScoopLoader>
                </Box>
            </Dialog>
        )
    }

    const renderFields = () => {
        let nextButton = 'Create Data Source'
        let next = () => {
            handleSave()
            onClose()
        }
        if (selectedInbox && selectedInbox.extractDefinition) {
            nextButton = 'Save Data Source'
        } else if (!selectedInbox && selectedObject && selectedObject.allowLoadTypeOption) {
            next = () => {
                setApiStep('loadType')
            }
        }
        return (
            <APIFields
                dialogOpen={open}
                APIFeatures={APIFeatures}
                stepBack={stepBack}
                onClose={onClose}
                selectedFieldList={selectedFieldList}
                selectedInbox={selectedInbox}
                objectList={objectList}
                fieldList={fieldList}
                filterMap={filterMap}
                reportName={reportName}
                selectedObject={selectedObject}
                setReportName={setReportName}
                setSelectedFieldList={setSelectedFieldList}
                extractNow={extractNow}
                setExtractNow={setExtractNow}
                handleObjectSelect={handleObjectSelect}
                nextButton={nextButton}
                next={next}
                startDate={startDate}
                setStartDate={setStartDate}
                errorMessage={errorMessage}
            ></APIFields>
        )
    }

    const renderStepContent = () => {
        switch (apiStep) {
            case 'start':
                if (RecipeCard) return renderOauthLink()
                return null
            case 'objectGroup':
                return renderObjectGroup()
            case 'googlePicker':
                return renderGooglePicker()
            case 'fields':
                return renderFields()
            case 'loadType':
                return (
                    <SelectType
                        handleContinue={(result) => {
                            handleSave()
                            onClose()
                            setType(null)
                        }}
                        stepBack={() => {
                            setApiStep('fields')
                        }}
                        setType={setType}
                        onClose={onClose}
                        isSnapshotDefault={selectedObject.defaultSnapshot}
                        disableAdvanced={true}
                        disableSpreadsheet={true}
                        continueButton={'Create Data Source'}
                    />
                )
            case 'apiKey':
                return renderAPIKey()
            case 'oauthLink':
                return renderOauthLink()
            default:
                return renderFields()
        }
    }

    return (
        <>
            {isLoading && renderLoading()}
            {!isLoading && renderStepContent()}
        </>
    )
}
